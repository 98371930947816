<template>
  <v-container
    id="entities"
  >
    <v-progress-linear
      indeterminate
      v-if="loading"
    ></v-progress-linear>
    <validation-observer
      v-else
      ref="observer"
      v-slot="{ valid }"
    >
      <v-form>
        <v-row>
          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              :name="'Username'"
            >
              <common-input-text-field
                v-model="form.username"
                :error-messages="errors"
                label="Username"
              />
            </validation-provider>
          </v-col>

          <v-col cols="6">
            <validation-provider
              v-slot="{ errors }"
              rules="required|email"
              :name="'Email'"
            >
              <common-input-text-field
                v-model="form.email"
                :error-messages="errors"
                label="Email"
              />
            </validation-provider>
          </v-col>

          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              rules=""
              :name="'Password'"
            >
              <common-input-text-field
                v-model="form.password"
                :error-messages="errors"
                label="Password"
                type="password"
              />
            </validation-provider>
          </v-col>

          <v-col
            v-if="user.role === 'admin'"
            cols="12"
          >
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              :name="'Role'"
            > 
              <common-input-select
                :items="CONSTS.USER_ROLES"
                v-model="form.role"
                :error-messages="errors"
                label="Role"
              />
            </validation-provider>
          </v-col>
        </v-row>
      </v-form>

      <v-row
        justify="end"
        no-gutters
      >
        <v-btn
          color="primary"
          :disabled="!valid"
          :loading="saving"
          @click="save"
          class="mr-0"
        >
          Save
        </v-btn>
      </v-row>
    </validation-observer>
  </v-container>
</template>

<script>
  import ApiWrapper from '@/shared/utils/ApiWrapper';
  const DEFAULT = {
    name: '',
  }

  export default {
    name: 'OrgForm',

    components: {
    },

    props: {
      id: {
        type: Number,
      },
    },

    data: () => ({
      loading: false,
      form: null,
      saving: false,
    }),

    computed: {
      user () {
        return this.$store.state.user
      },
    },

    watch: {
    },

    async created () {
      this.loadData()
    },

    methods: {
      async loadData () {
        if (!this.id) {
          this.form = {
            role: 'user',
          }
        } else {
          this.loading = true
          try {
            this.form = (await ApiWrapper.get(`/api/users/${this.id}`)).data
          } catch (err) {
            this.$store.dispatch('app/handleError', {
              err,
              message: 'Failed to load',
            })
          }
          this.loading = false
        }
      },
      async save () {
        this.saving = true
        try {
          if (!this.form.id) {
            this.form = (await ApiWrapper.post('/api/users', this.form)).data
          } else {
            this.form = (await ApiWrapper.put(`/api/users/${this.id}`, this.form)).data
          }
          this.$router.push({ name: 'Users' })
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to save',
          })
        }
        this.saving = false
      },
    },
  }
</script>

<style lang="sass">
</style>
