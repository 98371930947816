import CopyRight from "@/components/copyright/copyright";

import { getDefaultComponents } from "./helper";

export default [
  {
    path: "/copyright",
    ...getDefaultComponents(CopyRight)
  }
];
