<template>
  <div class="order">
    <div class="no-sidebar-container">
      <div class="main-title">Order</div>
    </div>
    <div class="order-content">
      <div class="mb-2">To: {{emailTos}}</div>

      <div>
        <v-textarea
          label="E-Mail Content"
          v-model="content"
          auto-grow
        ></v-textarea>
      </div>
      <button @click="startSearch()" class="modal-btn" :style="{background: user.theme}" style="margin-left: 0px">
        <img v-if="isLoading" style="width:25px;" src="../../img/spinner_white.svg"/>
        Order Now
      </button>
    </div>
  </div>
</template>
<script>
import StandardInput from "../form_elements/standard_input";
import ApiWrapper from '@/shared/utils/ApiWrapper';

import { mapGetters, mapActions } from 'vuex';
export default {
  name: "Order",
  data() {
    return {
      emailTos: 'jason.erb@unitedcorporate.com',
      
      isLoading: false,
      content: ''
    };
  },
  components: {
    StandardInput
  },
  mouted() {},
  methods: {
    
    startSearch() {
      if (!this.emailTos || !this.content)
        return;
      this.isLoading = true;
      ApiWrapper
        .post(
          "/api/order/order",  
          {
            content: this.content,
            emails:this.emailTos.split(',')
          }
        )
        .then(res => {
          this.isLoading = false;
          alert('Email sent to ' + this.emailTos);
        })
        .catch(err => {
          this.isLoading = false;
          alert('Something went wrong. We will resolve it soon.');
        });

    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),}
};
</script>
<style>
.order-content {
  margin: 20px;
}
</style>
