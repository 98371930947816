<template>
  <v-snackbar
    :timeout="message.timeout"
    :top="message.y === 'top'"
    :bottom="message.y === 'bottom'"
    :right="message.x === 'right'"
    :left="message.x === 'left'"
    :multi-line="message.mode === 'multi-line'"
    :vertical="message.mode === 'vertical'"
    :color="message.color"
    v-model="snackbar"
  >
    {{ message.text }}
    <v-btn color="#fff" flat @click.native="snackbar = false">Close</v-btn>
  </v-snackbar>
</template>

<script>
  export default {
    data () {
      return {
        snackbar: false
        // y: 'bottom',
        // x: null,
        // mode: '',
        // timeout: 4000,
        // text: ''
      }
    },
    watch: {
      message: function (msg) {
        if (msg) {
          this.snackbar = true
        }
      }
    },
    computed: {
      message () {
        return this.$store.getters['message']
      }
    }
  }
</script>

<style scoped>

</style>
