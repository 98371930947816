<template>
  <div class="splashes">
    <a @click="handleClickSplash(splash)" class="splash-card" :class="splashCircleClass(splash)" v-for="(splash, index) in currentSplashes" :key="index">

      <div class="splash-overlay"></div>
      <div class="splash-circle" :style="splashCircleStyle(splash)">
        <span class="text-inner" v-if="splash.value"
          :style="{
            fontSize: splash.value > 999 ? '38px' : '60px'
          }
        ">{{ splash.value }}</span>
        <span class="text-inner" v-if="!splash.value" style="font-size: 20px">Enter</span>
      </div>
      <p>{{splash.fieldLabel}}</p>
    </a>

  </div>
</template>
<script>

import ApiWrapper from '@/shared/utils/ApiWrapper';

import { mapGetters, mapActions } from 'vuex';
const PREDEFINED_COLORS = ['yellow', 'green', 'blue', 'red'];

export default {
  name: "Splashes",
  props: ['splashes', 'currentSplashLabel'],
  data() {
    return {

    };
  },
  components: {
  },
  mouted() {},
  methods: {
    splashCircleClass(splash) {
      console.log(splash.color)
      if (splash.color
        && PREDEFINED_COLORS.indexOf(splash.color.trim().toLowerCase()) !== -1) {
        return {
          [splash.color]: true
        }
      } else {
        return {
          Yellow: true
        }
      }
    },
    splashCircleStyle(splash) {
      return {}
    },
    handleClickSplash(splash) {
      setTimeout(() => {
        this.$emit('handleClickSplash', splash);
      }, 100);
    }
  },
  computed: {
    currentSplashes() {
      return this.splashes.filter(splash => {
        if (this.currentSplashLabel) {
          return this.currentSplashLabel === splash.parent
        } else {
          return !splash.parent
        }
      })
    }
  },
  mounted() {
  }
};
</script>
<style>
.splashes {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}
.text-inner {
  color: #4C5656;
  z-index: 222;
  font-size: 60px;
}
.Yellow {
  --bg-color: #ffd861;
  --bg-color-light: #ffeeba;
  --text-color-hover: #4C5656;
  --box-shadow-color: rgba(255, 215, 97, 0.48);
}
.Green {
  --bg-color: #0f0;
  --bg-color-light: #ffeeba;
  --text-color-hover: #4C5656;
  --box-shadow-color: rgba(255, 215, 97, 0.48);
}
.Blue {
  --bg-color: #00f;
  --bg-color-light: #ffeeba;
  --text-color-hover: #4C5656;
  --box-shadow-color: rgba(255, 215, 97, 0.48);
}
.Red {
  --bg-color: #f00;
  --bg-color-light: #ffeeba;
  --text-color-hover: #4C5656;
  --box-shadow-color: rgba(255, 215, 97, 0.48);
}

.splash-card {
  width: 220px;
  background: #fff;
  border-top-right-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  box-shadow: 0 14px 26px rgba(0,0,0,0.04);
  transition: all 0.3s ease-out;
  text-decoration: none;
  margin-bottom: 20px;
}

.splash-card:hover {
  transform: translateY(-5px) scale(1.005) translateZ(0);
  box-shadow: 0 24px 36px rgba(0,0,0,0.11),
    0 24px 46px var(--box-shadow-color);
}

.splash-card:hover .splash-overlay {
  transform: scale(4) translateZ(0);
}

.splash-card:hover .splash-circle {
  border-color: var(--bg-color-light);
  background: var(--bg-color);
}

.splash-card:hover .splash-circle:after {
  background: var(--bg-color-light);
}

.splash-card:hover p {
  color: var(--text-color-hover);
}

.splash-card:active {
  transform: scale(1) translateZ(0);
  box-shadow: 0 15px 24px rgba(0,0,0,0.11),
    0 15px 24px var(--box-shadow-color);
}

.splash-card p {
  font-size: 17px;
  color: #4C5656;
  margin-top: 30px;
  z-index: 1000;
  transition: color 0.3s ease-out;
  padding: 0px 10px;
}

.splash-circle {
  margin-top: 30px;
  width: 131px;
  height: 131px;
  border-radius: 50%;
  background: #fff;
  border: 2px solid var(--bg-color);
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 1;
  transition: all 0.3s ease-out;
}

.splash-circle:after {
  content: "";
  width: 118px;
  height: 118px;
  display: block;
  position: absolute;
  background: var(--bg-color);
  border-radius: 50%;
  top: 5px;
  left: 5px;
  transition: opacity 0.3s ease-out;
}

.splash-circle svg {
  z-index: 10000;
  transform: translateZ(0);
}

.splash-overlay {
  width: 118px;
  position: absolute; 
  height: 118px;
  border-radius: 50%;
  background: var(--bg-color);
  top: 35px;
  left: 50px;
  z-index: 0;
  transition: transform 0.3s ease-out;
}
</style>
