<template>
  <common-common-filter-card
    class="my-3"
    :has-more-filters="false"
  >
    <template v-slot:header>
      <v-row
        dense
        class="mt-1"
      >
        <v-col
          cols="3"
        >
          <common-input-text-field
            v-model="internalValue.username.$regex"
            placeholder="Name"
            color="primary"
            clearable
            hide-details
            dense
          />
        </v-col>
        <!-- <v-col
          cols="3"
        >
          <common-input-text-field
            v-model="internalValue.email"
            placeholder="Email"
            color="primary"
            clearable
            hide-details
            dense
          />
        </v-col> -->
        <v-col
          cols="9"
        >
          <v-fab-transition>
            <v-btn
              color="orange darken-2"
              dark
              absolute
              top
              right
              fab
              :to="{ name: 'UserCreate' }"
            >
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-fab-transition>
        </v-col>
      </v-row>
    </template>
    <template v-slot:content>
    </template>
  </common-common-filter-card>
</template>

<script>
  export default {
    name: 'Filter',

    components: {
    },

    props: {
      value: {
        type: Object,
        default: () => {},
      },
    },

    data: () => ({
    }),

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    watch: {
      // internalValue: {
      //   handler (val) {
      //     if (!val.name) {
      //       this.internalValue.name = { $regex: '' }
      //     }
      //   },
      //   immediate: true,
      // }
    }
  }
</script>
