<template>
<div class='standard-input-item' :style="'max-width:'+width">
  <validation-provider
    v-slot="{ errors }"
    :rules="ruleSets"
    :name="field"
  >
    <v-text-field
      v-model="inputValue"
      :label="field"
      :type="type"
      :name="name"
      @input="handleInput"
      :placeholder="placeholder"
      :error-messages="errors"
    />
  </validation-provider>
</div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "StandardInput",
  data: () => ({
    inputValue: ''
  }),
  mounted() {
  },
  methods: {
    handleInput (e) {
      this.$emit('input', this.inputValue)
    }
  },
  watch: {
    value: {
      handler () {
        this.value = val
      },
      immediate: true,
    },
  },
  computed: {
    ruleSets () {
      return {
        required: this.required ? true : false,
      }
    },
    ...mapGetters({
      user: 'user',
    }),
  },
  props: ["type", "width", "name", "field", "required", "value", "id", "placeholder"],
  inject: ["$validator"]
};
</script>
