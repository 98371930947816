<template>
  <v-card
    class="ma-0 d-flex"
    elevation="0"
    :color="color"
  >
    <common-btn
      v-if="hasBackButton"
      text
      color="#000"
      class="back-btn"
      x-small
      @click="goBack"
    >
      <v-icon>mdi-chevron-left</v-icon>
    </common-btn>
    <div
      class="d-flex my-2 flex-fill align-start flex-column flex-md-row align-md-center"
      no-gutters
    >
      <slot
        name="title"
        :parsedTitle="parsedTitle"
      >
        <div
          class="display-1 display-md-2 flex-fill ml-3 overflow-limit mr-3"
        >
          {{ parsedTitle }}
        </div>
      </slot>
      <div
        class="d-flex mr-3 justify-end mt-sm-2 align-self-sm-end"
      >
        <slot name="actions" />
      </div>
    </div>
  </v-card>
</template>

<script>
  /**
    <common-app-page-header
      :title="$getFileNameFromSignedUrl(value.document.signed_url)"
      @goBack="goBack"
    >
      <template v-slot:title>
        <common-btn>
          <v-icon class="mr-2">mdi-download</v-icon>
          Download
        </common-btn>
      </template>
      <template v-slot:actions>
        <common-btn>
          <v-icon class="mr-2">mdi-download</v-icon>
          Download
        </common-btn>
      </template>
    </common-app-page-header>
    */
  export default {
    name: 'PageHeader',

    props: {
      hasBackButton: {
        type: Boolean,
        default: true,
      },
      title: {
        type: String,
      },
      color: {
        type: String,
        default: 'rgb(242, 242, 242)',
      },
    },

    computed: {
      parsedTitle () {
        if (!this.title) {
          return ''
        }
        const limit = 20
        if (this.title.length > limit) {
          return this.title.slice(0, 20) + '...' + this.title.slice(-5)
        } else {
          return this.title
        }
      },
    },

    watch: {
    },

    data: () => ({
    }),

    created () {
    },

    methods: {
      goBack () {
        if (this.$listeners && this.$listeners.goBack) {
          this.$listeners.goBack()
        } else {
          this.$router.back()
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
  .v-card {
    min-height: 65px;
    border-radius: 5px;
    display: flex;
    align-items: center;
  }
  .v-btn.back-btn {
    border-right: 4px solid #e6e6e6;
    min-height: 50px;
    width: 50px;
    padding: 0px !important;
    @media (max-width: 960px) {
      border-right: 1px solid #e6e6e6;
      width: 30px;
    }
  }
  .overflow-limit {
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
    // white-space: nowrap;
  }
</style>
