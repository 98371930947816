<template>
  <validation-provider
    v-slot="props"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot
      name="input"
      v-bind="{ props, show, hide }"
    />
    <div
      class="mb-4"
      style="position: relative;"
    >
      <v-menu
        v-model="showMenu"
        offset-y
        :close-on-click="false"
        :attach="true"
        allow-overflow
        content-class="custom-scroll-1 error-menu"
      >
        <v-fade-transition
          v-for="(error, index) in props.errors"
          :key="index"
        >
          <v-alert
            dense
            type="error"
            class="px-2 py-2 ma-0"
            text
          >
            {{ error }}
          </v-alert>
        </v-fade-transition>
        <v-fade-transition
          v-if="props.errors.length === 0 && props.touched"
        >
          <v-alert
            dense
            type="success"
            class="px-2 py-2 ma-0"
            text
          >
            Looks good!
          </v-alert>
        </v-fade-transition>
      </v-menu>
    </div>
  </validation-provider>
</template>

<script>
  export default {
    name: 'CommonValidationProviderMenuError',

    props: {
    },

    data () {
      return {
        showMenu: false,
      }
    },

    methods: {
      show () {
        this.showMenu = true
      },
      hide () {
        this.showMenu = false
      },
    },
  }
</script>
<style lang="scss" scoped>
  .error-menu {
    width: 100%;
    background-color: #FFF;
  }
</style>
