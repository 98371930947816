<template>
  <div class="requests ma-4">
    <h1 class="mb-2">Requests</h1>
    <v-container>
      <request-list />
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'Requests',
  components: {
    RequestList: require('./components/List').default,
  },

  data () {
    return {
    }
  },

  watch: {
  },

  computed: {
  },

  methods: {
  },

  async mounted () {
  }
}
</script>

<style type="sass">
</style>