import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { store } from "./store/store.js";
import vuetify from './plugins/vuetify';

import './plugins/vee-validate';
import './plugins/common';
import './mixins/mixin';


import 'vue-material-design-icons/styles.css';
import './styles/root.css'

Vue.config.productionTip = false
export const settingsEventBus = new Vue()





import VueResource from "vue-resource";
import VTooltip from "v-tooltip";
import vSelect from "vue-select";
import VueCroppie from "vue-croppie";
import Loader from "vue-spinner/src/RingLoader.vue";
import pusher from "vue-pusher";
import VueMq from 'vue-mq';
import VueProgressBar from 'vue-progressbar'
import Viewer from 'v-viewer'
import VueScrollTo from 'vue-scrollto';
import infiniteScroll from 'vue-infinite-scroll'

import 'viewerjs/dist/viewer.css'
require("./styles/bootstrap.min.css");
require("./styles/font-awesome.min.css");
require("./styles/css.css");
require("./styles/animate.css");
require("./styles/tooltip.css");

import 'croppie/croppie.css' // import the croppie css manually


import "@ag-grid-community/core/dist/styles/ag-grid.css";
import "@ag-grid-community/core/dist/styles/ag-theme-balham.css";
import "bootstrap/dist/css/bootstrap.css";

import '@ag-grid-community/client-side-row-model'
import '@ag-grid-community/infinite-row-model'
import '@ag-grid-community/csv-export'
import iFrameResize from 'iframe-resizer/js/iframeResizer'

Vue.directive('resize', {
  bind: function(el, { value = {} }) {
    el.addEventListener('load', () => iFrameResize(value, el))
  }
})


Vue.config.productionTip = false;
Vue.use(infiniteScroll)
Vue.use(pusher, {
  api_key: "edc0fafa92d65aa9bace",
  options: {
    cluster: "us2",
    encrypted: true
  }
});
Vue.use(VueResource);
Vue.use(VTooltip);
Vue.use(VueCroppie);
// Vue.component("v-select", vSelect);
Vue.component("v-loader", Loader);
Vue.use(Viewer);

Vue.use(VueScrollTo, {
  container: "body",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

Vue.use(VueProgressBar, {
  color: 'rgb(255, 255, 255)',
  failedColor: 'red',
  thickness: '2px',

  transition: {
    speed: '20s',
    opacity: '0.6s',
    termination: 500
  },
})
Vue.use(VueMq, {
  breakpoints: {
    sm: 728,
    md: 1200,
    lg: Infinity
  }
})

Vue.directive("click-outside", {
  bind: function(el, binding, vnode) {
    el.event = function(event) {
      // here I check that click was outside the el and his childrens
      if (!(el === event.target || el.contains(event.target))) {
        // and if it did, call method provided in attribute value
        vnode.context[binding.expression](event);
      }
    };
    document.body.addEventListener("click", el.event);
  },
  unbind: function(el) {
    document.body.removeEventListener("click", el.event);
  }
});





new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')


