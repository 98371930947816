<template>
  <div class="users ma-4">
    <h1 class="mb-2">{{ id ? 'Edit' : 'Create' }} User</h1>
    <v-container>
      <v-card>
        <user-form
          :id="id"
        />
      </v-card>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'OrgEdit',
  components: {
    UserForm: require('./components/UserForm').default,
  },

  data () {
    return {
    }
  },

  watch: {
  },

  computed: {
    id () {
      return this.$route.params.id
    },
  },

  methods: {
  },

  async mounted () {
  }
}
</script>

<style type="sass">
</style>