<template>
  <div class="main-data-container animated-fast fadeInUp">
    <div v-for="(data, i) in filteredMainData" :key="i" class="data-container">

      <div class="data-item" @click="$emit('toggleDropdown', i)" :style="{'border-bottom': '1px solid ' + user.buttonBorder.hex}">
        <div style="display: flex; flex-direction: row; align-items: center">
          <i class="fa fa-minus" v-if="data.show"></i>
          <i class="fa fa-plus" v-if="!data.show"></i>
          <div class="data-title" v-html="formatWithSearch(data.title)"></div>
        </div>
        <div class="major-images">
          <div class="major-image-item"  :v-viewer="{title: (image, imageData) => { return data.images[index].desc ? data.images[index].desc : '';},}" v-for="(img,index) in data.images" :key="index">
            <img :src="img.link" />
          </div>
        </div>
      </div>

      <div class="data-elements" v-if="data.show"  :style="{'background': ((data.images && data.images.length > 0 && data.images[0].position && data.images[0].position.charAt(0).toLowerCase() == 's') ? ('url('+ data.images[0].link + ')  no-repeat center center fixed') : 'transparent')}">
        <div class="major-images" v-if="data.images && data.images.length > 0 && data.images[0].position && data.images[0].position.charAt(0).toLowerCase() == 't'">
          <div class="major-image-item"   v-viewer="{title: (image, imageData) => { return data.images[index].desc ? data.images[index].desc : '';},}">
            <img :src="img.link"  style="height: 100px" v-for="(img,index) in data.images" :key="index"/>
          </div>
        </div>

        <div v-for="(dat, i2) in data.data" :key="i2" class="data-item-item animated-fast fadeIn"  :class="{'left' : (dat.just != undefined && dat.just.charAt(0).toLowerCase() == 'l'), 'right' : (dat.just != undefined && dat.just.charAt(0).toLowerCase() == 'r'), 'center' : (dat.just != undefined && dat.just.charAt(0).toLowerCase() == 'c')}">
          <div
            v-if="
              (!currentEditDataItem ||
                (currentEditDataItem && (i2 !== currentEditDataItem.index || i !== currentEditDataItem.sectionIndex))
              )"
            class="data-item-title"
            v-html="formatWithSearch(dat.title)"
          ></div>
          <div
            :style="{ color: dat.hover ? '#66d0f7' : '#000000' }"
            v-if="(!dat.source) &&
              (!currentEditDataItem ||
                (currentEditDataItem && (i2 !== currentEditDataItem.index || i !== currentEditDataItem.sectionIndex))
              )"
            class="data-item-value animated-fast fadeInUp"
            v-tooltip="{ content:dat.hover, placement:'top'}"
            v-html="formatWithSearch(dat.formatted)"
          ></div>

          <div
            v-if="(dat.source) &&
              (!currentEditDataItem ||
                (currentEditDataItem && (i2 !== currentEditDataItem.index || i !== currentEditDataItem.sectionIndex))
              )"
            class="data-item-value animated-fast fadeInUp"
            v-tooltip="{ content:dat.hover, placement:'top'}"
          >
            <a :href="makeLink(dat.source)" target="_blank">
              <span v-html="formatWithSearch(dat.formatted)"></span>
              <!-- <v-icon class="ml-2" size="12" color="primary">mdi-open-in-new</v-icon> -->
            </a>
          </div>
          <div
            v-if="currentEditDataItem && i2 === currentEditDataItem.index && i === currentEditDataItem.sectionIndex"
            class="data-item-title"
            style="width: 80%"
          >
            {{ currentEditDataItem.item.title }}
            <v-text-field
              label="Value"
              v-model="currentEditDataItem.item.formatted"
              hide-details
              class="mb-2"
            />
            <v-text-field
              label="Source"
              v-model="currentEditDataItem.item.source"
              hide-details
              placeholder="Link to open..."
              class="mb-2"
            />
            <v-text-field
              label="Hover message"
              v-model="currentEditDataItem.item.hover"
              hide-details
              placeholder="Message to show on hover"
              class="mb-2"
            />
            <v-text-field
              label="Comment"
              v-model="currentEditDataItem.item.comment"
              placeholder="The reason why you edit..."
              hide-details
              class="mb-2"
            />
          </div>

          <div class="data-item-edit">
            <v-btn 
              v-if="
              (!currentEditDataItem ||
                (currentEditDataItem && (i2 !== currentEditDataItem.index || i !== currentEditDataItem.sectionIndex))
              )"
              icon x-small :ripple="false" @click="$emit('startEdit', dat, i, i2)"><v-icon>mdi-pencil</v-icon></v-btn>
            <v-btn 
              :loading="currentEditDataItemLoading"
              v-if="currentEditDataItem && i2 === currentEditDataItem.index && i === currentEditDataItem.sectionIndex"
              icon x-small :ripple="false" @click="$emit('closeEdit', true)"><v-icon>mdi-check</v-icon></v-btn>
            <v-btn 
              v-if="currentEditDataItem && i2 === currentEditDataItem.index && i === currentEditDataItem.sectionIndex"
              icon x-small :ripple="false" @click="$emit('closeEdit', false)"><v-icon>mdi-close</v-icon></v-btn>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "MainDataContainer",

  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },

  props: ['searchMainDataKey', 'filteredMainData', 'currentEditDataItem', 'currentEditDataItemLoading'],

  data () {
    return {
      formatWithSearch(str) {
        if (!this.searchMainDataKey)
          return str;
        else {
          let match = new RegExp("(" + this.searchMainDataKey + ")","gi"); 
          if (!str) {
            return str;
          }

          return str.replace(match, "<span style='background-color: #FFFF00'>$1</span>")
        }
      },
      makeLink(link) {
        if (link.indexOf("http") < 0) {
          return "http://" + link;
        } else {
          return link;
        }
      },
    }
  },
}
</script>