<template>
  <v-checkbox
    v-bind="{ ...defaultAttrs, ...$attrs }"
    v-on="$listeners"
  >
    <template
      v-for="(_, slot) of $slots"
      v-slot:[slot]
    >
      <slot :name="slot" />
    </template>
  </v-checkbox>
</template>

<script>
  export default {
    name: 'BaseInputCheckbox',

    props: {
    },

    data () {
      return {
        defaultAttrs: {
          color: 'primary',
        },
      }
    },
  }
</script>
