<template lang="html">
  <div class="app-footer" :style="{
    'background':  user ? user.theme : '#b30b30'
  }">
    Powered by CommonBrain © {{ new Date().getFullYear()
 }}
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "AppFooter",
  data() {
    return {
    };
  },
  methods: {
  },
  computed: {
    userId() {
      return this.$store.state.user.id;
    },
    ...mapGetters({
      user: 'user',
    }),
  }
};
</script>
<style>
  .app-footer {
    height: 30px;
    width: 100%;
    position: fixed;
    bottom: 0px;
    z-index: 100;
    color: white;

    display: flex;
    justify-content: center; 
    align-items: center;
  }

</style>