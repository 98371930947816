<template lang="html">
  <v-layout column wrap justify-center align-center>
    <v-alert
      :value="showAlert"
      color="#c60d35"
      style="width: 400px"
    >
      Thank you for your contact. We will reach you soon.
    </v-alert>

    <v-form
      ref="contactForm"
      v-model="valid"
      lazy-validation
      style="width: 400px"
    >
      <v-flex xs12>
        <v-text-field
          color="#c60d35"
          v-model="email"
          :rules="emailRules"
          label="E-mail"
          required
        ></v-text-field>

        <v-textarea
          color="#c60d35"
          label="Message..."
          v-model="description"
        ></v-textarea>

        <v-btn
          color="#66d0f7"
          style="background: #c60d35; color: #fff"
          @click="validate"
          :disabled="showAlert"
        >
          Contact
        </v-btn>

      </v-flex>
    </v-form>
  </v-layout>
</template>
<script>
import auth from "../../auth";
export default {
  name: "ContactForm",
  props: [],
  data() {
    return {
      showAlert: false,

      valid: true,
      email: '',
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+/.test(v) || 'E-mail must be valid'
      ],
      description: ''
    };
  },
  $_veeValidate: {
    validator: "new" // give me a new validator each time.
  },
  methods: {
    validate () {
      if (this.$refs.contactForm.validate()) {
        this.showAlert = true; 
      }
    },
  }
};
</script>
<style>
</style>


