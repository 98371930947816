<template>
  <v-row
    no-gutters
  >
    <v-col
      v-for="(link, i) in links"
      :key="i"
      class="text-center mb-sm-0 mb-5"
      cols="auto"
    >
      <a
        :href="link.href"
        class="body-1 mr-0 text-decoration-none"
        :class="{
          'primary--text': !link.none
        }"
        rel="noopener"
        :target="link.href === '#' ? '_self' : '_blank'"
        v-text="link.text"
      />
    </v-col>

    <v-spacer class="hidden-sm-and-down" />

    <v-col
      cols="12"
      md="auto"
    >
      <div class="body-1 font-weight-light pt-6 pt-md-0 text-center">
        &copy; {{ new Date().getFullYear() }} All Rights Reserved
      </div>
    </v-col>
  </v-row>
</template>
<script>
  export default {
    name: 'DashboardCoreFooter',

    computed: {
      branding () {
        return this.$store.getters['account/branding']
      },
    },

    data: () => ({
      links: [
        {
          text: 'Powered by Trellis Software Inc. ' + BUILD_NUMBER,
          href: '#',
          none: true,
        },
        {
          text: 'Contact Us',
          href: '#',
        },
        {
          text: 'Terms and Conditions',
          href: '#',
        },
        {
          text: 'Privacy',
          href: '#',
        },
      ],
    }),
  }
</script>
