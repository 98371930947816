<template>
  <div>
    <v-overlay
      :value="internalDialog"
      dark
    />
    <v-dialog
      v-model="internalDialog"
      v-bind="$attrs"
      v-on="$listeners"
      fullscreen
      :hide-overlay="false"
      transition="scroll-x-reverse-transition"
      content-class="common-side-modal"
    >
      <template v-slot:activator="{ on }">
        <slot
          name="activator"
          v-bind="{ on }"
        />
      </template>

      <!-- Let's show the default modal if title is specified -->
      <v-card
        v-if="title"
      >
        <v-row
          no-gutters
          class="py-4 px-4"
        >
          <span
            class="display-1"
          >
            {{ title }}
          </span>
          <v-spacer />
          <v-icon
            aria-label="Close"
            @click="close"
          >
            mdi-close
          </v-icon>
        </v-row>
        <v-divider class='mx-4' />
        <slot
          v-bind="{ close, internalDialog }"
        />
      </v-card>

      <!-- If not, let it customize its way -->
      <slot
        v-if="!title"
        name="default"
        v-bind="{ close, internalDialog }"
      />

      <template
        v-for="(_, slot) of $slots"
        v-slot:[slot]
      >
        <slot :name="slot" />
      </template>
    </v-dialog>
  </div>
</template>

<script>
  export default {
    name: 'BaseCommonSideModal',

    components: {
    },

    props: {
      title: {
        type: String,
        default: '',
      },
    },

    data: () => ({
      internalDialog: false,
    }),

    computed: {
    },

    watch: {
      internalDialog (val) {
      },
    },

    async created () {
    },

    methods: {
      close () {
        this.internalDialog = false
      },
    },
  }
</script>

<style lang="scss" scoped>
  ::v-deep .common-side-modal {
    min-width: 320px;
    width: 30vw;
    justify-self: flex-end;
    right: 0;
    left: auto;
    @media (max-width: 780px) {
      width: 100vw;
    }
  }
</style>
