import moment from 'moment'

export function $date (date) {
  return moment(date).isValid() ? moment(date).format('MM/DD/YYYY') : ''
}

export function $time (date) {
  return moment(date).isValid() ? moment(date).format('hh:mm a') : ''
}

export function $currency (num, currency = 'USD') {
  if (!num) {
    return '$0'
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
    minimumFractionDigits: 0,
  })
  return formatter.format(num)
}

export function $formatThousandReadable (value, currency = 'USD') {
  if (!value) {
    return '$0'
  }

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency,
  })

  if (value >= 1000000) {
    return `$${Number(value / 1000000).toFixed(
      2,
    )}M`
  } else if (value >= 1000) {
    return `$${Number(value / 1000).toFixed(
      2,
    )}K`
  }

  return `${formatter.format(value)}`
}

export function $datetime (date) {
  return moment(date).isValid() ? moment(date).format('MM/DD/YYYY hh:mm a') : ''
}