<template>
  <v-row no-gutters>
    <v-col
      v-if="$attrs.label"
      cols="12"
      class="mb-1"
    >
      <common-input-label
        class="text-left"
        :class="{ 'error--text': $attrs['hide-details'] && $attrs['error-messages'] && $attrs['error-messages'].length > 0 }"
      >
        {{ $attrs.label }}
      </common-input-label>
    </v-col>
    <v-col cols="12">
      <v-textarea
        v-bind="{ ...defaultAttrs, ...$attrs }"
        v-on="$listeners"
      >
        <template
          v-for="(_, slot) of $slots"
          v-slot:[slot]
        >
          <slot :name="slot" />
        </template>
      </v-textarea>
    </v-col>
  </v-row>
</template>

<script>
  export default {
    name: 'BaseInputTextArea',

    props: {
    },

    data () {
      return {
        defaultAttrs: {
          dense: true,
          filled: true,
          singleLine: true,
          color: 'primary',
          validateOnBlur: true,
          placeholder: this.$attrs.label ? ' ' : '',
        },
      }
    },
  }
</script>
