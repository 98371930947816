import axios from 'axios'
import CONSTANTS from '@/shared/constants';

export default {

  get: (url, config = {}, $Progress) => { // eslint-disable-line
    console.log(config)
    $Progress && $Progress.start();
    return axios({
      method: 'get',
      url: CONSTANTS.API_BASE_URL + url,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      params: config.params,
    }).then(data => {
      $Progress && $Progress.finish();
      return data
    }).catch(err=> {
      $Progress && $Progress.fail()
      throw err;
    });
  },

  download: (url, config = {}, $Progress) => { // eslint-disable-line
    $Progress && $Progress.start();
    return axios({
      method: 'get',
      url: CONSTANTS.API_BASE_URL + url,
      headers: {
        Accept: "application/octet-stream",
        'Content-Type': "application/octet-stream",
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      responseType: 'arraybuffer'
    }).then(data => {
      $Progress && $Progress.finish();
      return data
    }).catch(err=> {
      $Progress && $Progress.fail()
      throw err;
    });
  },

  post: (url, data, config = {}, $Progress) => { // eslint-disable-line
    $Progress && $Progress.start();
    return axios({
      method: 'post',
      url: CONSTANTS.API_BASE_URL + url,
      data: data,
      ...config,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    }).then(data => {
      $Progress && $Progress.finish();
      return data
    }).catch(err=> {
      $Progress && $Progress.fail()
      throw err;
    });
  },

  put: (url, data, config = {}, $Progress) => { // eslint-disable-line
    $Progress && $Progress.start();
    return axios({
      method: 'put',
      url: CONSTANTS.API_BASE_URL + url,
      data: data,
      ...config,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    }).then(data => {
      $Progress && $Progress.finish();
      return data
    }).catch(err=> {
      $Progress && $Progress.fail()
      throw err;
    });
  },

  patch: (url, data, config = {}, $Progress) => { // eslint-disable-line
    $Progress && $Progress.start();
    return axios({
      method: 'patch',
      url: CONSTANTS.API_BASE_URL + url,
      data: data,
      ...config,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    }).then(data => {
      $Progress && $Progress.finish();
      return data
    }).catch(err=> {
      $Progress && $Progress.fail()
      throw err;
    });
  },


  put: (url, data, config = {}, $Progress) => { // eslint-disable-line
    $Progress && $Progress.start();
    return axios({
      method: 'put',
      url: CONSTANTS.API_BASE_URL + url,
      data: data,
      ...config,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    }).then(data => {
      $Progress && $Progress.finish();
      return data
    }).catch(err=> {
      $Progress && $Progress.fail()
      throw err;
    });
  },

  delete: (url, config = {}, $Progress) => { // eslint-disable-line
    $Progress && $Progress.start();
    return axios({
      method: 'delete',
      url: CONSTANTS.API_BASE_URL + url,
      ...config,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("token")
      }
    }).then(data => {
      $Progress && $Progress.finish();
      return data
    }).catch(err=> {
      $Progress && $Progress.fail()
      throw err;
    });
  },

  upload (url, body, progressCallback, method = 'post') {
    return axios[method](CONSTANTS.API_BASE_URL + url, body, {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: "Bearer " + localStorage.getItem("token")
      },
      onUploadProgress: (progressEvent) => {
        const uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100), 10)

        progressCallback && progressCallback(uploadPercentage)
      },
    })
  },

  composeParams (options) {
    const { sortDesc, sortBy, page = 1, itemsPerPage = 10, filters, query } = options
    const params = {
      page,
      limit: itemsPerPage,
    }

    if (sortDesc && sortDesc.length === 1) {
      params.order = `${sortDesc[0] ? 1 : -1}`
      params.sort = sortBy[0]
    }

    if (filters) {
      params.filter = JSON.stringify(filters)
    }
    if (query && Array.isArray(query)) {
      params.fields = `{${query.join(' ')}}`
    }

    return params
  }
}
