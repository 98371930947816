<template>
  <v-overlay :value="loading">
    Loading...
  </v-overlay>
</template>

<script>
  export default {
    name: 'PageLoader',

    props: {
      loading: {
        type: Boolean,
      },
    },
  }
</script>
