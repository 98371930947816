

import logoClosing from './img/logo_closing.png';
import logoHound from '@/img/logo_hound.png';

import logoWhiteClosing from '@/img/logo-white_closing.png';
import logoWhiteHound from '@/img/logo-white_hound.png';

// <!-- Change institution -->
const institutions = [{
  id: 'closing',
  title: 'Closingtable Unitedcorp',
  logo: logoClosing,
  logoWhite: logoWhiteClosing,
}, {
  id: 'hounds',
  title: 'DD Hounds',
  logo: logoHound,
  logoWhite: logoWhiteHound,
}]

export const getInst = () => {
  return institutions[0];
}
