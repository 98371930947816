<template>
  <v-card
    flat
    class="pa-2"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <slot />
  </v-card>
</template>

<script>
  /**
    <common-common-filter-card>
      <any content>
    </common-common-filter-card>
    */
  export default {
    name: 'BaseCommonCard',

    components: {
    },

    props: {
    },

    computed: {
    },

    watch: {
    },

    methods: {
    },

    data () {
      return {
      }
    },
  }
</script>
<style lang="scss" scoped>
</style>
