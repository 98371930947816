<template>
  <div class="requests ma-4">
    <h1 class="mb-2">{{ id ? 'Edit' : 'Create' }} Request</h1>
    <v-container>
      <v-card>
        <request-form
          :id="id"
        />
      </v-card>
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'OrgEdit',
  components: {
    RequestForm: require('./components/RequestForm').default,
  },

  data () {
    return {
    }
  },

  watch: {
  },

  computed: {
    id () {
      return this.$route.params.id
    },
  },

  methods: {
  },

  async mounted () {
  }
}
</script>

<style type="sass">
</style>