import Vue from "vue";
import Router from "vue-router";
import ProfileRoutes from "./profile";
import ProjectRoutes from "./project";
import FileRoutes from "./file";
import OfacRoutes from "./ofac";
import TemplatesRoutes from "./templates";
import CopyrightRoutes from "./copyright";
import OrderRoutes from "./order";

import UserRoutes from "./user";
import RequestRoutes from "./request";
//import { store } from "../store/store.js";

import LoginClosingBakckup from "@/components/login/login_closing_backup";
import LoginClosing from "@/components/login/login_closing";
import LoginHound from "@/components/login/login_hound";
import * as INST from '@/institutions';
const institution = INST.getInst();

const LOGIN = {
  closing: LoginClosing,
  hounds: LoginHound,
}
var baseRoutes = [
  {
    path: "/",
    components: {
      login: LOGIN[institution.id]
    }
  },
  {
    path: "/private-signup",
    components: {
      login: LoginClosingBakckup
    }
  },
  {
    path: "/services",
    components: {
      login: LOGIN[institution.id]
    }
  },
  {
    path: "/contact-us",
    components: {
      login: LOGIN[institution.id]
    }
  }
];

const routes = baseRoutes
  .concat(ProfileRoutes)
  .concat(ProjectRoutes)
  .concat(FileRoutes)
  .concat(OfacRoutes)
  .concat(TemplatesRoutes)
  .concat(CopyrightRoutes)
  .concat(OrderRoutes)
  .concat(UserRoutes)
  .concat(RequestRoutes);

Vue.use(Router);

var router = new Router({
  routes: routes,
  // mode: 'history'
});

router.beforeEach((to, from, next) => {
  return next();
});

export default router;
