<template>
  <common-input-autocomplete
    v-model="internalValue"
    clearable
    v-bind="$attrs"
    :items="items"
    :loading="loading"
    :item-text="itemText"
    :item-value="itemValue"
    :search-input.sync="search"

    v-on="$listeners"
  />
</template>

<script>
  // import { mapState } from 'vuex'
  import { debounce } from 'lodash'
  import { api } from '@/api'
  export default {
    name: 'BaseAppEntityItemSelector',

    props: {
      value: {
        required: true,
        validator: v => true,
      },
      entity: {
        type: String,
        required: true,
      },
      include: {
        type: String,
        enum: (val) => ['common', 'both', 'tenant'].indexOf(val) !== -1,
        default: 'tenant',
      },
      itemText: {
        type: String,
        default: 'name',
      },
      itemValue: {
        type: String,
        default: 'id',
      },
      preFilters: {
        type: Object,
        default: () => ({}),
      },
    },

    computed: {
      internalValue: {
        get () {
          return this.value
        },
        set (val) {
          this.$emit('input', val)
        },
      },
    },

    watch: {
      search: {
        handler: debounce(function (val) {
          this.querySelections(val)
        }, 500),
      },
      include (val) {
        this.search = ''
        this.items = []
        this.querySelections(this.search)
      },
    },

    data: () => ({
      search: null,
      items: [],
      loading: false,
    }),

    created () {
      this.querySelections('')
    },

    methods: {
      async querySelections (v) {
        this.loading = true
        const promises = []
        const params = {
          params: {
            page: 1,
            limit: 20,
            name: v,
            query: `{${this.itemText}, ${this.itemValue}}`,
            ...this.preFilters,
          },
        }
        if (this.include === 'common' || this.include === 'both') {
          promises.push(api
            .get(`${this.entity}`, params, { base: 'common' })
            .then(result => {
              return result.data.results.map(item => ({
                ...item,
                source: 'common',
              }))
            }),
          )
        }
        if (this.include !== 'both') {
          promises.push(api
            .get(`${this.entity}`, params, { base: this.include })
            .then(result => {
              return result.data.results.map(item => ({
                ...item,
                source: '',
              }))
            }),
          )
        }
        const apiResponse = await Promise.all(promises)
        this.items = apiResponse.reduce((r1, r2) => r1.concat(r2), [])
        this.loading = false
      },
    },
  }
</script>
