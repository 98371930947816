<template>
  <div style="height: 100%; display: flex; flex-direction: column;">
      <ag-grid-vue style="width: 100%; height: 100%;" class="ag-theme-balham"
       :gridOptions="gridOptions"
       :columnDefs="columnDefs"
       :rowData="filteredDashes"
       :sideBar="sideBar"
       :modules="modules"

       :defaultColDef="{
        sortable: true,
        resizable: true,
        filter: true
       }"

       :groupHeaders="true"
       :suppressRowClickSelection="true"
       rowSelection="multiple"

       @grid-ready="onReady"
       @model-updated="onModelUpdated"
       @cell-clicked="onCellClicked"
       @cell-double-clicked="onCellDoubleClicked"
       @cell-context-menu="onCellContextMenu"
       @cell-value-changed="onCellValueChanged"
       @cell-focused="onCellFocused"
       @row-selected="onRowSelected"
       @selection-changed="onSelectionChanged"
       @filter-modified="onFilterModified"
       @virtual-row-removed="onVirtualRowRemoved"
       @row-clicked="onRowClicked"
       @column-everything-changed="onColumnEvent"
       @column-row-group-changed="onColumnEvent"
       @column-value-Changed="onColumnEvent"
       @column-moved="onColumnEvent"
       @column-visible="onColumnEvent"
       @column-group-Opened="onColumnEvent"
       @column-resized="onColumnEvent"
       @column-pinned-count-changed="onColumnEvent"


       :enableRangeSelection="false"
       :debug="true"
       :autoHeight="true"
       :deltaRowDataMode="true"
       :getRowNodeId="getRowNodeId"
      >
      </ag-grid-vue>
    </div>
</template>

<script>
  import {AgGridVue} from "@ag-grid-community/vue";

  // for community features
  // import {AllCommunityModules} from "@ag-grid-community/all-modules";

  // for enterprise features
  import {AllModules} from "@ag-grid-enterprise/all-modules";

  // import HeaderGroupComponent from './HeaderGroupComponent.vue';

  export default {
    props: ['filteredDashes', 'dashHeaders'],
    data() {
      return {
        gridOptions: null,
        columnDefs: null,
        sideBar: false,
        modules: AllModules,
      }
    },
    components: {
      AgGridVue
    },
    watch: {
    },
    methods: {
      getRowNodeId(row) {
        return row.index;
      },
      createColumnDefs() {
        this.columnDefs = [
          // {
          //   headerName: '#', width: 30, checkboxSelection: true, sortable: false,
          //   suppressMenu: true, pinned: true
          // },
          {
            headerName: this.dashHeaders['CBDashItemName'], field: "dashName",
            flex: 2, pinned: true, minWidth: 400,
            filter: 'agTextColumnFilter',
            cellRenderer: dashNameCellRenderer,
          },  
          {
            headerName: this.dashHeaders['CBDashItemName1'], field: "name2",
            flex: 1, pinned: true,
            // filter: 'agTextColumnFilter',
          },
          {
            headerName: this.dashHeaders['CBDashItemName2'], field: "status",
            flex: 1, pinned: true,
            // filter: 'agTextColumnFilter',
          },
          {
            headerName: this.dashHeaders['CBDashItemName3'], field: "geography",
            flex: 1, pinned: true,
            filter: 'agTextColumnFilter',
          },
          {
            headerName: this.dashHeaders['CBDashItemName4'], field: "other",
            flex: 1, pinned: true,
            filter: 'agTextColumnFilter',
          },
          {
            headerName: this.dashHeaders['CBDashItemName5'], field: "other5",
            flex: 1, pinned: true,
            filter: 'agTextColumnFilter',
          },
        ];
      },
      onModelUpdated() {
        // console.log('onModelUpdated');
        // console.log(this.gridOptions.api.getModel());
      },

      onReady() {
        console.log('onReady');
        this.gridOptions.api.sizeColumnsToFit();
      },

      onCellClicked(event) {
        if (event.colDef.field === 'dashName') {
          this.$emit('activateDash', event.data);
        }
      },

      onCellValueChanged(event) {
        console.log('onCellValueChanged: ' + event.oldValue + ' to ' + event.newValue);
      },

      onCellDoubleClicked(event) {
        console.log('onCellDoubleClicked: ' + event.rowIndex + ' ' + event.colDef.field);
      },

      onCellContextMenu(event) {
        console.log('onCellContextMenu: ' + event.rowIndex + ' ' + event.colDef.field);
      },

      onCellFocused(event) {
        console.log('onCellFocused: (' + event.rowIndex + ',' + event.colIndex + ')');
      },

      // taking out, as when we 'select all', it prints to much to the console!!
      // eslint-disable-next-line
      onRowSelected(event) {
        // console.log('onRowSelected: ' + event.node.data.name);
      },

      onSelectionChanged() {
        console.log('selectionChanged');
      },

      onFilterModified() {
        console.log('onFilterModified');
      },

      onSortChanged() {
        this.reloadData();
      },
      onFilterChanged() {
        this.reloadData();
      },

      reloadData () {
      },

      // // eslint-disable-next-line
      onVirtualRowRemoved(event) {
        // because this event gets fired LOTS of times, we don't print it to the
        // console. if you want to see it, just uncomment out this line
        // console.log('onVirtualRowRemoved: ' + event.rowIndex);
      },

      onRowClicked(event) {
        console.log('onRowClicked: ' + event.node.data.name);
      },

      onQuickFilterChanged(event) {
        this.gridOptions.api.setQuickFilter(event.target.value);
      },

      // here we use one generic event to handle all the column type events.
      // the method just prints the event name
      onColumnEvent(event) {
        console.log('onColumnEvent: ' + event);
      }
    },
    beforeMount() {
      this.gridOptions = {};
      this.createColumnDefs();
    }
  }

  function dashNameCellRenderer(params) {
    return `<a href="#">${params.value}</a>`;
  }
</script>

<style>
  .ag-cell {
    padding-top: 2px !important;
    padding-bottom: 2px !important;
  }

  .ag-menu {
    z-index: 200;
  }

  .toolbar button {
    margin-left: 5px;
    margin-right: 5px;
    padding: 2px;
  }
  .ag-body-viewport-wrapper.ag-layout-normal {
    overflow-x: scroll;
  }
</style>
