<template>
  <div class="top-file-bar darken-1" :style="{'background': user.theme}" v-if="user._id">

    <div 
      @click="selectScreen('splash')"
      :class="{
        'top-nav-item': true, 
        'active': currentScreen == 'splash'
      }" 
      :style="{'color': '#fff'}">Splash</div>
    <div 
      @click="selectScreen('dash')"
      :class="{
        'top-nav-item': true, 
        'active': currentScreen !== 'splash'
      }" 
      :style="{'color': '#fff'}">Dash</div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import { mapGetters, mapActions } from 'vuex';
export default {
  name: "header_main",
  props: ["sheets", "activate", "dashes", "activeDash", "selectScreen", "activeSheet", "currentScreen"],
  data() {
    return {
      userDropdown: false,

      userLogin: false,
      signup: false
    };
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),

  },
  watch:{
  }, 
  methods: {
    ...mapMutations("projects", ["resetState"]),
  },
  components: {
  }
};
</script>
<style lang="scss">
.top-file-bar {
  z-index: 1;
  height: 30px;
  width: 100%;
  text-align: center;
  position: relative;
  background: #66d0f7;
  display: flex;
  justify-content: center;

  .top-nav-item {
    display: flex;
    align-items: center;

    color: #fff;
    text-shadow: 1px 1px rgba(255, 255, 255, 0.1);
    font-size: 15px;
    margin: 0px 50px;
    text-align: center;
    font-weight: 300;
    cursor: pointer;
    position: relative;
    &:hover {
      color: #31a6d1;
    }
    &.active {
      color: #fff;
      border-bottom: solid 2px #fff;
    }
  }
}
</style>
