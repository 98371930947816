<template>
  <div style="overflow:hidden">
    <LearnMore v-if="learnMore" :hide="toggleLearnMore"/>
    <UserLogin :hide="toggleUserLogin" v-if="userLogin"/>
    <Signup :hide="toggleSignup" v-if="signup" />
    <div class="main-container">
      <div class="web-top-bar">
        <!-- <img :src="institution.logo"/> -->
        <i v-if="mobile" @click="toggleMobileNav" class="fa fa-bars nav-toggle"></i>
        <div class="top-nav" :class="{'mobile':mobile, 'collapsed':!navOpen}">
          <ul>
            <!-- <li @click="scrollTo('#home')">Home</li>
            <li @click="scrollTo('#about-us')">About</li>
            <li @click="scrollTo('#services')">Services</li>
            <li @click="scrollTo('#company')">Company</li>
            <li @click="scrollTo('#contact-us')">Contact Us</li> -->
            <li @click="toggleUserLogin">User Login</li>
            <li @click="signup = !signup">Sign Up</li>
            <li><div @click="toggleLearnMore" class="get-started">Get Started</div></li>
          </ul>
        </div>
      </div>
      <!-- <div v-if="headerScroll" class="web-top-bar scrolled" :class="{'animated-fast fadeInDown': headerScroll}">
        <img  :src="instition.logoWhite"/>
        <i v-if="mobile" @click="toggleMobileNav" class="fa fa-bars nav-toggle"></i>
        <div class="top-nav" :class="{'mobile':mobile, 'collapsed':!navOpen}">
          <ul>
            <li @click="scrollTo('#home')">Home</li>
            <li @click="scrollTo('#about-us')">About</li>
            <li @click="scrollTo('#services')">Services</li>
            <li @click="scrollTo('#company')">Company</li>
            <li @click="scrollTo('#contact-us')">Contact Us</li>
            <li><div @click="toggleLearnMore" class="get-started">Get Started</div></li>
          </ul>
        </div>
      </div> -->


    </div>

    <iframe src="https://jason48572.wixsite.com/ddhounds" width="100%" frameborder="0"  v-resize="{ log: true }" style="height: 3650px"></iframe>

  </div>
</template>
<script>
import StandardInput from "../form_elements/standard_input";
import LearnMore from "./learn_more";
import UserLogin from "./login_form";
import Signup from "./signup";
import { mapActions } from "vuex";
import VueScrollTo from 'vue-scrollto';
import ContactForm from './ContactForm';
import { getInst } from '@/institutions';
export default {
  name: "login",
  data() {
    return {
      hasError: false,
      navOpen: false,
      errorMessage: "",
      mobile: false,
      headerScroll: false,
      learnMore: false,
      userLogin: false,
      signup: false
    };
  },

  mounted() {
    // window.document.addEventListener("scroll", this.getScrollTop);
    if (this.$mq == "sm") {
      this.mobile = true;
    }

    if (this.$route.path == '/services') {
      var cancelScroll = this.$scrollTo('#services', 500, {offset: -70});  
    } else if (this.$route.path == '/about-us') {
      var cancelScroll = this.$scrollTo('#about-us', 500, {offset: -70});  
    } else if (this.$route.path == '/contact-us') {
      var cancelScroll = this.$scrollTo('#contact-us', 500, {offset: -70});  
    } else if (this.$route.path == '/services') {
      var cancelScroll = this.$scrollTo('#services', 500, {offset: -70});  
    }
    
  },
  watch: {
    $mq(val) {
      if (val == "sm") {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    }
  },
  computed: {

    institution() {
      return getInst();
    },
  },
  methods: {
    ...mapActions(["setUser"]),

    toggleUserLogin() {
      this.userLogin = !this.userLogin;
    },
    toggleSignup() {
      this.signup = !this.signup;
    },
    toggleLearnMore() {
      this.learnMore = !this.learnMore;
    },
    getScrollTop() {
      var scrollTop = window.window.scrollY;
      var loginPanel = window.document.getElementById("login-panel")
        .clientHeight;
      if (scrollTop > loginPanel - 100) {
        this.headerScroll = true;
      } else {
        this.headerScroll = false;
      }
    },
    toggleMobileNav() {
      this.navOpen = !this.navOpen;
    },
    scrollTo(target) {
      var cancelScroll = this.$scrollTo(target, 500, {offset: -70});  
    }
  },
  components: {
    StandardInput,
    LearnMore,
    UserLogin,
    Signup,
    ContactForm
  }
};
</script>
<style>
.service-container {
  text-align: center;
  color: #fff;
  background: #5f5f64;
  padding: 25px;
}
.service-container h1 {
  font-size: 25pt;
  display: flex;
  align-items: center;
  height: 100px;
  justify-content: center;
}
.service-container b {
  margin-top: 45px;
  font-size: 1.2em;
  margin-bottom: 10px;
  color: #b9ebff;
  display: inline-block;
  line-height: 20px;
}
.sub-text {
  text-align: left;
  display: inline-block;
  font-size: 14pt;
}
.footer-links {
  display: flex;
  flex-direction: column;
  text-align: left;
  width: 100%;
}
.footer-link {
  color: #fff;
  cursor: pointer;
  padding: 15px;
  font-weight: 500;
}
.section-1 {
  background: #c60d35;
  width: 100%;
  margin-top: -1px;
  padding: 55px 15px;
}
.section-2 {
  background-image: url("../../img/neural_net2.jpg");
  background-size: cover;
  background-position: center;
  padding: 55px 15px;
  width: 100%;
}
.section-3 {
  background: #fff;
  padding: 55px 15px;
  width: 100%;
}
.section-4 {
  background: #c60d35;
  padding: 55px 15px;
  width: 100%;
}
.section-5 {
  background: #fff;
  padding: 55px 15px;
  width: 100%; 
}
.footer {
  background: #4d585d;
  padding: 55px 15px;
  width: 100%;
}
.top-nav ul {
  height: 100%;
  top: 0;
  position: absolute;
  right: 0;
  padding: 15px;
  display: flex;
  align-items: center;
}
.top-nav ul li {
  padding: 15px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
}
.top-nav ul li:hover {
  color: #b9ebff;
}
.web-top-bar {
  height: 70px;
  width: 100%;
  display: flex;
  padding: 5px 15px;
  align-items: center;
  color: #c60d35;
  position: relative;
  z-index: 10;
}
.web-top-bar .top-nav li,
.web-top-bar .top-nav li div {
  color: #c60d35;
  border-color: #c60d35;
}
.web-top-bar.scrolled .top-nav li,
.web-top-bar.scrolled .top-nav li div {
  color: #fff;
  border-color: #fff;
}
.web-top-bar.scrolled {
  top: 0;
  position: fixed;
  background: #b30b30;
  box-shadow: 0px 0px 18px rgba(0, 0, 0, 0.2);
}
.get-started {
  display: flex;
  align-items: center;
  justify-content: center;
  border: solid 2px #ffffff;
  border-radius: 3px;
  height: 40px;
  width: 150px;
  transition: all 0.2s ease;
}
.get-started:hover {
  color: #c60d35 !important;
  background: #fff !important;
  border-color: #fff;
}
.main-splash {
  padding-top: 85px;
  text-align: left;
  position: relative;
  display: flex;
  align-items: center;
  max-width: 1200px;
  width: 100%;
  margin: 0px 50px;
}
.main-splash h1 {
  margin: 0;
  padding: 0;
  font-size: 55pt;
  font-weight: 200;
}
.main-btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #c60d35;
  border: solid 2px #c60d35;
  border-radius: 3px;
  height: 40px;
  width: 150px;
  transition: all 0.2s ease;
  cursor: pointer;
}
.main-splash span {
  display: inline-block;
  line-height: 25px;
  font-size: 15pt;
  margin-bottom: 25px;
  margin-top: 10px;
}
.main-btn:hover {
  color: #c60d35;
  background: #fff;
  border-color: #fff;
}
@media (max-width: 1250px) {
  .service-container h1 {
    font-size: 20pt;
  }
}
@media (max-width: 728px) {
  .web-top-bar {
    /* position: absolute; */
  }
  .service-container h1 {
    font-size: 20pt;
  }
  .sub-text {
    font-size: 12pt;
  }
  i.nav-toggle {
    padding: 5px;
    font-size: 12pt;
    color: #c60d35;
    border: solid 1px #c60d35;
    border-radius: 3px;
    height: 35px;
    width: 35px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: auto;
  }
  .web-top-bar.scrolled i.nav-toggle {
    color: #fff;
    border: solid 1px #fff;
  }
  .top-nav ul {
    height: unset;
    background: #c60d35;
    width: 100%;
    top: 70px;
    box-shadow: 1px 1px 10px -50px rgba(0, 0, 0, 0.2);
    position: absolute;
    right: 0;
    flex-direction: column;
    align-items: center;
    transform: translateX(0);
    opacity: 1;
    transition: transform 0.3s ease;
  }
  .top-nav ul li,
  .top-nav ul li div {
    color: #fff !important;
    border-color: #fff !important;
  }
  .top-nav.collapsed ul {
    transform: translateX(100%);
    opacity: 0;
  }
  .top-nav.mobile ul {
  }
  .main-splash span {
    font-size: 12pt;
  }
  .main-splash {
    text-align: center;
    padding-top: unset !important;
  }
  .main-splash h1 {
    font-size: 24pt;
  }
}
iframe {
  width: 1px;
  min-width: 100%;
}
</style>
