import * as CONSTS from '@/constants'
import Vue from 'vue'

import * as mixins from '@/components/common/mixins'

Vue.mixin({
  data: function () {
    return {
      CONSTS: { ...CONSTS },
    }
  },
  methods: {
    ...mixins,
  },
})