<template>
<div>
  <AddProject v-if="addProjectOpen" :hide="toggleAddProject" :create="projectCreated"/>
    <div class="sidebar-container ">
      <v-toolbar
        :color="user.theme"
      >
        <v-toolbar-title>
          <span style="color: #FFF">Projects</span>
          <div v-if="isLoading" class="spinner-container animated flash infinite">
            <img class="spinner-big"  src="../../img/spinner.svg" alt="" />
          </div>
        </v-toolbar-title>

        <v-spacer></v-spacer>

        <v-btn icon @click="toggleDragging" color="#FFF" :loading="draggingSaving">
          <v-icon>{{ nowDragging ? 'mdi-check' : 'mdi-format-list-numbered' }}</v-icon>
        </v-btn>
        <v-btn icon @click="toggleAddProject" color="#FFF">
          <v-icon>add</v-icon>
        </v-btn>
      </v-toolbar>
      <v-list two-line subheader v-if="!isLoading && projects.length > 0">
        <draggable v-model="projects" style="width: 100%;" :options="{ handle:'.drag-handle'}">
          <v-list-item
            v-for="(project, index) in projects"
            :key="index"
            @click="activateProject(index, project._id)"
          >
            <v-icon color="primary" class="drag-handle" v-if="nowDragging">mdi-drag</v-icon>
            <v-list-item-avatar>
              <v-icon>mdi-folder</v-icon>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title v-text="project.project_name"></v-list-item-title>
              <span v-if="project.projectType" style="font-size: 10px;">{{ project.projectType}}</span>
              <v-list-item-subtitle>{{ project.project_create_date }}</v-list-item-subtitle>
            </v-list-item-content>

            <v-list-item-action>
              <v-btn icon  @click.stop="activateProject(index, project._id, true)">
                <v-icon color="grey lighten-1">mdi-menu</v-icon>
              </v-btn>
            </v-list-item-action>
          </v-list-item>
        </draggable>
      </v-list>
               
    </div>
  </div>
</template>
<script>
import AddProject from "./add_project";
import { mapGetters, mapActions } from 'vuex';
import draggable from 'vuedraggable';
import ApiWrapper from '@/shared/utils/ApiWrapper';

export default {
  name: "project-sidebar",
  data() {
    return {
      hovered: -1,
      addProjectOpen: false,
      activeProject: -1,
      isLoading: false,

      nowDragging: false,
      draggingSaving: false,
    };
  },
  methods: {
    ...mapActions(["getProjects", "getFiles"]),
    async toggleDragging () {
      if (!this.nowDragging) {
        this.nowDragging = true
        const projects = this.projects.map((proj, index) => {
          if (!proj.order) {
            proj.order = index + 1
          }
          return proj
        })
        this.projects = projects
      } else {
        this.draggingSaving = true
        const projects = this.projects.map((proj, index) => {
          proj.order = index + 1
          return proj
        })
        this.projects = projects
        await Promise.all(projects.map(proj => {
          return ApiWrapper.patch(`/api/projects/${proj._id}`, { order: proj.order })
        }))
        this.draggingSaving = false
        this.nowDragging = false
      }
    },
    toggleAddProject() {
      this.addProjectOpen = !this.addProjectOpen;
    },
    projectCreated() {
      this.addProjectOpen = false;
      this.activeProject = this.projects.length;
      this.refreshProjects();
    },
    mouseOver(num) {
      this.hovered = num;
    },
    async activateProject(i, id, showList) {
      this.activeProject = i;
      
      const files = await this.getFiles({ projectId: id });

      if (files.length == 1 && !showList) {
        this.$router.push(/projects/ + id + '/file/'+files[0]._id);  
      } else {
        this.$router.push("/projects/" + id);
      }
    },
    refreshProjects() {
      this.getProjects().then(res => {
        this.isLoading = false;
        if (this.$route.params.projectId != null) {
          for (var i = 0; i < this.projects.length; i++) {
            if (this.$route.params.projectId == this.projects[i]._id) {
              this.activeProject = i;
            }
          }
        }
      });
    }
  },
  computed: {
    projects: {
      get () {
        let projects = this.$store.state.projectStore.projects
        if (this.user.visibleProjectType) {
          projects = projects.filter(p => {
            return !p.projectType || this.user.visibleProjectType.indexOf(p.projectType) !== -1
          })
        }
        return projects;
      },
      set (val) {
        this.$store.commit('GET_PROJECTS', val)
      },
    },
    projectId() {
      return this.$route.params.projectId;
    },
    userId() {
      return this.$store.state.user.id;
    },
    ...mapGetters({
      user: 'user',
    })
  },
  mounted() {
    this.getProjects();
    if (this.$route.params.projectId != null) {
      for (var i = 0; i < this.projects.length; i++) {
        if (this.$route.params.projectId == this.projects[i]._id) {
          this.activeProject = i;
        }
      }
    }
  },
  watch: {
    userId(val, oldVal) {
      if (val != null && oldVal != val) {
        // this.getProjects().then(res => {
        //   this.isLoading = false;
        //   if (this.$route.params.projectId != null) {
        //     for (var i = 0; i < this.projects.length; i++) {
        //       if (this.$route.params.projectId == this.projects[i]._id) {
        //         this.activeProject = i;
        //       }
        //     }
        //   }
        // });
      }
    },
    projects(val) {
      if (val.length > 0) {
        this.isLoading = false;
      }
    },
    projectId(val, oldVal) {
      if (val == null) {
        // this.refreshProjects();
        this.activeProject = -1;
      }
      if (val != oldVal) {
        // this.refreshProjects();
      }
    }
  },
  components: {
    AddProject,
    draggable
  }
};
</script>
<style>
.spinner-container {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 25px;
  background: #eaeaea;
}
.spinner-container i {
  color: #aaa;
}

.drag-handle {
  cursor: move;
  cursor: -webkit-grabbing;
}
</style>
