<template>
  <div
    class="body-1 font-weight-light text-center"
  >
    For help and support, please email us at
    <a
      target="_blank"
      class="text-decoration-none"
      href="mailto:support@schedule.wetnosesgrooming.com"
    >
      support@schedule.wetnosesgrooming.com
    </a>
  </div>
</template>

<script>
  export default {
    name: 'LoginHelpZendesk',

    props: {
    },
  }
</script>
