var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"entities"}},[(_vm.loading)?_c('v-progress-linear',{attrs:{"indeterminate":""}}):_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var valid = ref.valid;
return [_c('v-form',[_c('v-row',[_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required","name":'Username'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"error-messages":errors,"label":"Username"},model:{value:(_vm.form.username),callback:function ($$v) {_vm.$set(_vm.form, "username", $$v)},expression:"form.username"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"6"}},[_c('validation-provider',{attrs:{"rules":"required|email","name":'Email'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"error-messages":errors,"label":"Email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})]}}],null,true)})],1),_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"","name":'Password'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-text-field',{attrs:{"error-messages":errors,"label":"Password","type":"password"},model:{value:(_vm.form.password),callback:function ($$v) {_vm.$set(_vm.form, "password", $$v)},expression:"form.password"}})]}}],null,true)})],1),(_vm.user.role === 'admin')?_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":'Role'},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('common-input-select',{attrs:{"items":_vm.CONSTS.USER_ROLES,"error-messages":errors,"label":"Role"},model:{value:(_vm.form.role),callback:function ($$v) {_vm.$set(_vm.form, "role", $$v)},expression:"form.role"}})]}}],null,true)})],1):_vm._e()],1)],1),_c('v-row',{attrs:{"justify":"end","no-gutters":""}},[_c('v-btn',{staticClass:"mr-0",attrs:{"color":"primary","disabled":!valid,"loading":_vm.saving},on:{"click":_vm.save}},[_vm._v(" Save ")])],1)]}}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }