/** THIS IS COMMON CODE. PLEASE DO NOT CHANGE WITHOUT DISCUSSION WITH THE TEAM **/
// eslint-disable-next-line
import { api } from '@/api'

// initial state
const state = {
  loading: false,
  message: {
    color: 'success',
    timeout: 4000,
    mode: '',
    text: '',
    x: null,
    y: 'bottom',
  },
}

// getters
const getters = {
  loading: state => state.loading,
  message: state => state.message,
}

// actions
const actions = {
  async loading ({ commit }, state) {
    commit('setLoading', state)
  },
  async message ({ commit }, message) {
    commit('showMessage', message)
  },
  async handleError ({ commit }, payload) {
    let message = payload.message

    try {
      message = payload.err.response.data.detail || payload.message
    } catch (err) {
      // pass
    }

    commit('showMessage', {
      text: message,
      color: 'error',
    })
  },
}

// mutations
const mutations = {
  setLoading (state, loading) {
    state.loading = loading
  },
  showMessage (state, message) {
    state.message = Object.assign({}, {
      color: 'success',
      snackbar: false,
      y: 'bottom',
      x: null,
      mode: '',
      timeout: 4000,
      text: '',
    }, message)
  },
}

export default {
  state,
  getters,
  actions,
  mutations,
  namespaced: true,
}
