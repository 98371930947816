
import { getDefaultComponents } from "./helper";

export default [
  // USERS
  {
    path: '/users',
    name: 'Users',
    component: require('@/views/dashboard/users/Users.vue').default,
    meta: {
      roles: ['admin']
    },
    ...getDefaultComponents(require('@/views/dashboard/users/Users.vue').default),
  },
  {
    path: '/users/:id/edit',
    name: 'UserEdit',
    component: require('@/views/dashboard/users/UserEdit.vue').default,
    meta: {
      roles: ['admin']
    },
    ...getDefaultComponents(require('@/views/dashboard/users/UserEdit.vue').default),
  },
  {
    path: '/users/create',
    name: 'UserCreate',
    component: require('@/views/dashboard/users/UserEdit.vue').default,
    meta: {
      roles: ['admin']
    },
    ...getDefaultComponents(require('@/views/dashboard/users/UserEdit.vue').default),
  },
];
