<template>
  <v-app id="app">

    <message />
    <vue-progress-bar></vue-progress-bar>
    <router-view name="header" v-if="!globalLoader"></router-view>
    <router-view name="login" v-if="!globalLoader"></router-view>

    <v-content>
      <v-fade-transition name="fade" mode="out-in">
        <router-view v-if="!globalLoader">
        </router-view>
        <div v-if="globalLoader">
          <Loader />
        </div>
      </v-fade-transition>
    </v-content>
    <AppFooter></AppFooter>

    <common-app-confirm
      ref="confirm"
      mode="ref"
    />
  </v-app>
</template>

<script>
import Loader from './components/loader'
import jwt_decode from "jwt-decode";
import { mapGetters, mapActions } from 'vuex';
const fakeUser = { theme: '#66d0f7', showHoverOnExport: false, buttonBorder: {hex: '#4a4a4a'}, showButtonBorders: true, fillButtons: true, showHyperlink: true};
import Message from './components/helpers/message'
import AppFooter from './components/layouts/footer'

export default {
  name: "App",
  components: {
    Loader,
    Message,
    AppFooter
  },
  
  methods: {
    ...mapActions(["setToken", "setUser"])
  },

  computed: {
    ...mapGetters({
      user: 'user',
      globalLoader: 'globalLoader',
    }),
  },

  data() {
    return {
    };
  },

  watch: {
    user: function (val) {
      this.$vuetify.theme.themes['light'].primary = val.theme
    },
  },

  mounted () {
    this.$root.$confirm = this.$refs.confirm.open
  },

  created() {
    const token = localStorage.getItem("token");
    if (token) {
      this.setToken(token);

      setTimeout(()=>{
        this.$store.dispatch('getCurrentUser').then(res=>{
          // this.$router.push("/projects");
        }).catch(err=>{
          this.$store.commit("SET_USER", fakeUser);
          this.$store.commit("SET_GLOBAL_LOADER", false);
        });
      }, 0)
    } else {
      // this.$router.push("/");
      // Can setup fake user account.
      // debugger
      this.$store.commit("SET_USER", fakeUser);
      this.$store.commit("SET_GLOBAL_LOADER", false);
    }
  },
};
</script>
