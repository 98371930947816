<template>
  <v-chip
    class="flex-fill justify-center mx-2"
    v-bind="$attrs"
    v-on="$listeners"
  >
    <v-tooltip
      bottom
    >
      <template v-slot:activator="{ attrs, on }">
        <div
          v-bind="attrs"
          v-on="on"
          class="overflow-hidden"
        >
          <slot />
        </div>
      </template>
      <span><slot /></span>
    </v-tooltip>
  </v-chip>
</template>

<script>
  /**
    <common-common-filter-card>
      <any content>
    </common-common-filter-card>
    */
  export default {
    name: 'BaseCommonCard',

    components: {
    },

    props: {
    },

    computed: {
    },

    watch: {
    },

    methods: {
    },

    data () {
      return {
      }
    },
  }
</script>
<style lang="scss" scoped>
  .overflow-hidden {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
</style>
