import Vue from "vue";
import Vuex from "vuex";
import jwt_decode from "jwt-decode";

// stores
import projectStore from "./projects";
import fileStore from "./files";
import app from './app';

// import createPersistedState from "vuex-persistedstate";
import ApiWrapper from '@/shared/utils/ApiWrapper';
Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    projectStore,
    fileStore,
    app,
  },
  // plugins: [createPersistedState()],
  state: {
    user: {},
    globalLoader: true,
    message: {
      color: 'success',
      timeout: 4000,
      mode: '',
      text: '',
      x: null,
      y: 'bottom'
    },
  },
  getters: {
    user: (state)=>state.user,
    globalLoader: (state)=>state.globalLoader,
    message: (state) => state.message
  },
  mutations: {
    SET_USER(state, payload) {
      this.state.user = payload;
      return this.state;
    },
    SET_GLOBAL_LOADER(state, payload) {
      this.state.globalLoader = payload;
      return this.state;
    },
    SHOW_MESSAGE(state, payload) {
      this.state.message = Object.assign({}, {
        color: 'success',
        snackbar: false,
        y: 'bottom',
        x: null,
        mode: '',
        timeout: 4000,
        text: ''
      }, payload)
      return this.state;
    }
  },
  actions: {
    getCurrentUser({ state, commit, rootState }, payload) {
      if(!rootState.user._id)
        return new Promise((resolve, reject) => { reject(null); })

      return ApiWrapper
        .get("/api/users/" + rootState.user._id)
        .then(res => {
          commit("SET_USER", res.data);
          commit("SET_GLOBAL_LOADER", false);
      
          return res.data;
        });
    },

    setUser({ dispatch, commit }, payload) {
      commit("SET_USER", payload);
      dispatch("getCurrentUser");
      dispatch("getProjects");
    },
    updateUser({ dispatch, commit, rootState }, payload) {
      return ApiWrapper
        .patch("/api/users/" + rootState.user._id, payload)
        .then(res => {
          commit("SET_USER", payload);
          return res.data;
        });
    },
    setToken(context, payload) {
      var decoded = jwt_decode(payload);
      context.commit("SET_USER", decoded);
    }
  }
});
