<template>
  <div class="users ma-4">
    <h1 class="mb-2">Users</h1>
    <v-container>
      <user-list />
    </v-container>
  </div>
</template>

<script>

export default {
  name: 'Users',
  components: {
    UserList: require('./components/List').default,
  },

  data () {
    return {
    }
  },

  watch: {
  },

  computed: {
  },

  methods: {
  },

  async mounted () {
  }
}
</script>

<style type="sass">
</style>