<template lang="html">
  <div v-bind:class="{'overlay animated-fast fadeIn':visible,'overlay animated-fast fadeOut':!visible}" >
    <div v-bind:class="{'modal-1 animated-fast zoomIn':visible,'modal-1 animated-fast zoomOut':!visible}" style="width: 400px">
      <form class="login-form" @submit.prevent="submitForm">
      <div class="logo-container">
        <img class="small-logo" :src="institution.logo" alt="">
      </div>
      <img class="logo" :src="institution.logo" alt="">
      <div class="login-panel-title">Signup</div>
        <div class="login-box-input-item">
          <div class="login-box-input-icon"><i class="fa fa-user-circle-o" /></div>
          <input type="text" id="username" name="username" placeholder="Username" class="css-login-input-input" />
        </div>
        <div class="login-box-input-item">
          <div class="login-box-input-icon"><i class="fa fa-envelope" /></div>
          <input type="text" id="email" name="email" placeholder="Email" class="css-login-input-input" />
        </div>
        <div class="login-box-input-item">
          <div class="login-box-input-icon"><i class="fa fa-lock" /></div>
          <input type="password" id="password" name="password" placeholder="Password" class="css-login-input-input" />
        </div>
    <div class="modal-btn-container">
        <div @click="toggleVisible" class="modal-btn cancel">Cancel</div>
    <button class="modal-btn confirm" type="submit"><span v-if="!isLoading">Signup</span> <img v-if="isLoading" style="width:25px" src="../../img/spinner_white.svg"/></button>
    
    </div>
    <div v-if="hasError" class="alert-danger animated fadeIn" style="color:#ff0000; text-align:center; clear:both">{{errorMessage}}</div>
    
    </form>
    </div>
    </div>
</template>
<script>
import auth from "../../auth";
import { getInst } from '@/institutions';
export default {
  name: "signup_form",
  props: ["hide", "login"],
  data() {
    return {
      visible: true,
      hasError: false,
      isLoading: false,
      errorMessage: ""
    };
  },
  computed: {
    institution() {
      return getInst();
    }
  },
  methods: {
    toggleVisible() {
      var that = this;
      this.visible = false;
      setTimeout(function() {
        that.hide();
      }, 300);
    },
    submitForm() {
      this.isLoading = true;
      var form = event.target;
      var data = new FormData(form);
      data = data.entries();
      var obj = data.next();
      var retrieved = {};

      while (undefined !== obj.value) {
        retrieved[obj.value[0]] = obj.value[1];
        obj = data.next();
      }
      auth.signup(retrieved).then((res) => {
        console.log(res.data);
      }).catch(err => {
        this.isLoading = false;
        this.hasError = true;
        this.errorMessage = err.response.data.error;
      });
    }
  }
};
</script>
<style>
.signup-error{
  margin-bottom:10px;
}
</style>
