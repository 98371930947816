<template>
  <v-container
    id="entities"
  >
    <v-progress-linear
      indeterminate
      v-if="loading"
    ></v-progress-linear>
    <validation-observer
      v-else
      ref="observer"
      v-slot="{ valid }"
    >
      <v-form>
        <v-row>
          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              :name="'Title'"
            >
              <common-input-text-field
                v-model="form.title"
                :error-messages="errors"
                label="Title"
              />
            </validation-provider>
          </v-col>

          <v-col cols="12">
            <validation-provider
              v-slot="{ errors }"
              rules="required"
              :name="'Description'"
            >
              <common-input-textarea
                v-model="form.description"
                :error-messages="errors"
                label="Description"
              />
            </validation-provider>
          </v-col>

          <v-col cols="12" v-if="!form._id">
            <common-input-file
              label="Files"
              multiple
              @change="uploadFile"
            > 
              <template v-slot:append-outer>
                <v-progress-circular
                  v-if="loading"
                  :rotate="270"
                  :size="50"
                  :width="10"
                  :value="uploadingProgress"
                  color="primary"
                >
                  {{ uploadingProgress }}
                </v-progress-circular>
              </template>
            </common-input-file>
          </v-col>

          <v-col cols="12" v-if="form._id">
            <v-btn
              color="primary"
              block
              @click="downloadAllFiles"
            >
              Download all files
            </v-btn>
          </v-col>
        </v-row>
      </v-form>

      <v-row
        justify="end"
        no-gutters
        v-if="!form._id"
      >
        <v-btn
          color="primary"
          :disabled="!valid"
          :loading="saving"
          @click="save"
          class="mr-0"
        >
          Save
        </v-btn>
      </v-row>
    </validation-observer>
  </v-container>
</template>

<script>
  import ApiWrapper from '@/shared/utils/ApiWrapper';
  import { downloadAsFile } from '@/shared/index';
  import CONSTANTS from '@/shared/constants';

  const DEFAULT = {
    name: '',
  }

  export default {
    name: 'OrgForm',

    components: {
    },

    props: {
      id: {
        type: Number,
      },
    },

    data: () => ({
      loading: false,
      form: null,
      saving: false,
      uploadingProgress: 0,
      files: [],
    }),

    computed: {
      user () {
        return this.$store.state.user
      },
    },

    watch: {
    },

    async created () {
      this.loadData()
    },

    methods: {
      downloadAllFiles () {
        this.form.files.forEach(f => {
          const url = CONSTANTS.API_BASE_URL + `/api/download-path/${this.form.title}_${f.originalname}?url=${f.path}`
          downloadAsFile(url)
        })
      },
      uploadFile (files) {
        this.files = files
      },
      async loadData () {
        if (!this.id) {
          this.form = {
            files: [],
          }
        } else {
          this.loading = true
          try {
            this.form = (await ApiWrapper.get(`/api/requests/${this.id}`)).data
          } catch (err) {
            this.$store.dispatch('app/handleError', {
              err,
              message: 'Failed to load',
            })
          }
          this.loading = false
        }
      },
      async save () {
        this.saving = true
        try {
          const progressCallback = (progress) => {
            this.uploadingProgress = progress
          }

          const formData = new FormData()
          this.files.forEach(file => {
            formData.append('file[]', file)
          })
          formData.append('title', this.form.title)
          formData.append('description', this.form.description)
          formData.append('files[]', this.form.files)

          if (!this.form.id) {
            (await ApiWrapper.upload('/api/requests', formData)).data
          } else {
            (await ApiWrapper.put(`/api/requests/${this.id}`, this.form)).data
          }
          // this.$router.push({ name: 'Requests' })
        } catch (err) {
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to save',
          })
        }
        this.saving = false
      },
    },
  }
</script>

<style lang="sass">
</style>
