<template>
  <div>
    <list-filter
      v-model="filters"
    />
    <v-card>
      <v-data-table
        :headers="headers"
        :items="entities"
        :options.sync="options"
        :server-items-length="totalCount"
        :loading="loading"
      >
        <template v-slot:item.org_name="{ item }">
          <span>{{ item.org ? item.org.name : '' }}</span>
        </template>
        <template v-slot:item.createdAt="{ item }">
          <span>{{ $date(item.createdAt) }}</span>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-btn
            class="mr-2"
            color="primary"
            icon
            :to="{ name: 'UserEdit', params: { id: item._id }}"
          >
            <v-icon small dark>mdi-pencil</v-icon>
          </v-btn>
          <!-- <v-btn
            class="mr-2"
            color="primary"
            icon
            :to="{ name: 'OrgEdit', params: { id: item.id }}"
          >
            <v-icon small dark>mdi-eye</v-icon>
          </v-btn> -->
          <!-- <v-btn
            color="error"
            icon
            @click="deleteEntity(item)"
          >
            <v-icon small dark>mdi-delete</v-icon>
          </v-btn> -->
        </template>
        <template v-slot:no-data>
          <span> No Results Found. </span>
        </template>
      </v-data-table>
    </v-card>
  </div>
</template>

<script>
  import ApiWrapper from '@/shared/utils/ApiWrapper';
  import debounce from 'lodash/debounce'
  const TABLE_HEADERS = [
    { text: 'Username', value: 'username', sortable: true },
    { text: 'Email', value: 'email', sortable: true },
    // { text: 'Created', value: 'createdAt', sortable: true },
    { text: 'Actions', value: 'actions', sortable: false },
  ]
  export default {
    name: 'AdminUsers',

    components: {
      ListFilter: require('./Filter').default,
    },

    props: {
      preFilters: {
        type: Object,
        default: () => {},
      },
      hideFields: {
        validate: () => true,
        default: () => [],
      },
      query: {
        validate: () => true,
        default: null,
      },
    },

    watch: {
      options: {
        handler () {
          this.loadData()
        },
        deep: true,
      },
      filters: {
        handler: debounce(function (val) {
          this.loadData()
        }, 500),
        deep: true,
      },
      hideFields: {
        handler (val) {
          this.headers = TABLE_HEADERS.filter(header => val.indexOf(header.value) === -1)
        },
        immediate: true,
      },
    },

    data: () => ({
      loading: false,

      options: {},
      entities: [],
      totalCount: 0,
      headers: [],
      filters: {
        username: { $regex: '' }
      },
    }),

    computed: {
    },

    created () {
    },

    methods: {
      deleteEntity (item) {
        this.$root.$confirm({
          entity: null,
          message: 'Do you want to remove this user?',
          yesButton: 'Yes',
          noButton: 'Cancel',
          maxWidth: 400,
        })
          .then(async (confirm) => {
            if (confirm) {
              this.loading = true
              try {
                const data = await ApiWrapper.delete(`/api/users/${item.id}`)
                this.loadData()
              } catch(err) {
                this.$store.dispatch('app/handleError', {
                  err,
                  message: 'Failed to delete data',
                })
              }
              this.loading = false
            }
          })
          .catch(() => {
          })
      },
      async loadData () {
        this.loading = true
        try {
          const resp = await ApiWrapper.get('/api/users', {
            params: ApiWrapper.composeParams({
              ...this.options,
              filters: this.filters,
            })
          })
          this.totalCount = resp.data.totalDocs
          this.entities = resp.data.docs
        } catch (err) {
          console.log(err)
          this.$store.dispatch('app/handleError', {
            err,
            message: 'Failed to load data',
          })
        }
        this.loading = false
      },
    },
  }
</script>

<style lang="scss">
</style>
