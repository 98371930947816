<template>
  <v-btn
    class="ma-0"
    v-bind="{ ...defaultAttrs, ...$attrs }"
    v-on="$listeners"
  >
    <template
      v-for="(_, slot) of $slots"
      v-slot:[slot]
    >
      <slot :name="slot" />
    </template>
  </v-btn>
</template>

<script>
  export default {
    name: 'BaseBtn',

    props: {
    },

    data () {
      return {
        defaultAttrs: {
          color: 'primary',
        },
      }
    },
  }
</script>
<style lang="scss" scoped>
  .v-sheet button.v-btn.v-size--default:not(.v-btn--icon):not(.v-btn--fab) {
    padding: 10px 15px !important
  }
</style>
