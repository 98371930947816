<template>
  <v-toolbar
    v-bind="{ ...defaultAttrs, ...$attrs }"
    v-on="$listeners"
  >
    <v-btn
      icon
      dark
      @click="$emit('close')"
    >
      <v-icon>mdi-close</v-icon>
    </v-btn>
    <v-toolbar-title>
      <div
        class="display-1 display-md-2 flex-fill ml-3 overflow-limit mr-3"
      >
        {{ title }}
      </div>
    </v-toolbar-title>
    <v-spacer />
    <v-toolbar-items>
      <div
        class="d-flex mr-3 justify-end mt-sm-2 align-self-sm-end"
      >
        <slot name="actions" />
      </div>
    </v-toolbar-items>
  </v-toolbar>
</template>

<script>
  export default {
    name: 'PageHeader',

    props: {
      title: {
        type: String,
      },
    },

    computed: {
      parsedTitle () {
        if (!this.title) {
          return ''
        }
        const limit = 20
        if (this.title.length > limit) {
          return this.title.slice(0, 20) + '...' + this.title.slice(-5)
        } else {
          return this.title
        }
      },
    },

    watch: {
    },

    data: () => ({
    }),

    created () {
    },

    methods: {
      goBack () {
        if (this.$listeners && this.$listeners.goBack) {
          this.$listeners.goBack()
        } else {
          this.$router.back()
        }
      },
    },
  }
</script>
<style lang="scss" scoped>
</style>
