import Order from "@/components/order/order";

import { getDefaultComponents } from "./helper";

export default [
  {
    path: "/order",
    ...getDefaultComponents(Order)
  }
];
