let url;
if (window.location.hostname.indexOf('localhost') != -1) {
  url = 'http://localhost:5000'
} else {
  url = window.location.origin
}


export default {
  API_BASE_URL: url,
  PERMISSIONS: [
    {value: 0, text: 'No Access', pems: []},
    {value: 1, text: 'Can Read', pems: ['read']},
    {value: 2, text: 'Can Read and Download', pems: ['read', 'download']},
    {value: 3, text: 'Can Read, Download and Write', pems: ['read', 'download', 'write']}
  ]
}
