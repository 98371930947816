<template>
  <div style="overflow:hidden">
    <UserLogin v-if="userLogin"/>
  </div>
</template>
<script>
import StandardInput from "../form_elements/standard_input";
import LearnMore from "./learn_more";
import UserLogin from "./login_form";
import Signup from "./signup";
import { mapActions } from "vuex";
import VueScrollTo from 'vue-scrollto';
import ContactForm from './ContactForm';
import { getInst } from '@/institutions';

export default {
  name: "login_closing",
  data() {
    return {
      userLogin: true,
      mobile: false,
    };
  },
  computed: {
    institution() {
      return getInst();
    },
  },
  mounted() {
  },
  watch: {
    $mq(val) {
      if (val == "sm") {
        this.mobile = true;
      } else {
        this.mobile = false;
      }
    }
  },
  methods: {
    ...mapActions(["setUser"]),
    toggleUserLogin() {
      this.userLogin = !this.userLogin;
    },
  },
  components: {
    StandardInput,
    LearnMore,
    UserLogin,
    Signup,
    ContactForm
  }
};
</script>
<style>
</style>
