<template>
  <div class="breadcrumbs-cont mb-2" :style="{'border': '1px solid ' + user.buttonBorder.hex}">
    <div
      class="breadcrumbs-item"
      v-for="(breadcrumb, index) in breadcrumbs"
      :key="index"
    >
      <span
        :class="{ 'breadcrumb-linkable': index < breadcrumbs.length - 1 && breadcrumb.clickable }"
        @click="handleBreadCrumbClick(breadcrumb, index)">
        {{ breadcrumb.title }}
      </span>
      <span v-if="index < breadcrumbs.length - 1">/</span>
    </div>
  </div>
</template>
<script>

import auth from "../../auth.js";
import { mapGetters, mapActions } from 'vuex';

export default {
  name: "BreadCrumbs",
  data() {
    return {
    };
  },
  props: ["breadcrumbs"],
  methods: {
    handleBreadCrumbClick(breadcrumb, index) {
      if (index < this.breadcrumbs.length - 1) {
        this.$emit('handleBreadCrumbClick', breadcrumb);
      }
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),
  },
  components: {
  }
};
</script>

<style>
</style>
