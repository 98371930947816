
import { getDefaultComponents } from "./helper";

export default [
  // USERS
  {
    path: '/requests',
    name: 'Requests',
    component: require('@/views/dashboard/requests/Requests.vue').default,
    meta: {
      roles: ['admin']
    },
    ...getDefaultComponents(require('@/views/dashboard/requests/Requests.vue').default),
  },
  {
    path: '/requests/:id/edit',
    name: 'RequestEdit',
    component: require('@/views/dashboard/requests/RequestEdit.vue').default,
    meta: {
      roles: ['admin']
    },
    ...getDefaultComponents(require('@/views/dashboard/requests/RequestEdit.vue').default),
  },
  {
    path: '/requests/create',
    name: 'RequestCreate',
    component: require('@/views/dashboard/requests/RequestEdit.vue').default,
    meta: {
      roles: ['admin']
    },
    ...getDefaultComponents(require('@/views/dashboard/requests/RequestEdit.vue').default),
  },
];
