<template>
  <v-expansion-panels
    flat
    v-bind="$attrs"
    v-on="$listeners"
    v-model="internalValue"
  >
    <v-expansion-panel
      class="pa-2"
    >
      <v-expansion-panel-header>
        <div @click.stop.prevent>
          <slot name="header" />
        </div>
        <template v-slot:actions>
          <span
            v-if="hasMoreFilters"
            class="d-flex flex-row align-center ml-2"
          >
            <span class="grey--text text-caption">{{ internalValue === 0 ? 'Less' : 'More' }} Filters</span>
            <v-icon>$expand</v-icon>
          </span>
          <span v-else></span>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content v-if="hasMoreFilters">
        <slot name="content" />
      </v-expansion-panel-content>

      <template
        v-for="(_, slot) of $slots"
        v-slot:[slot]
      >
        <slot :name="slot" />
      </template>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
  // Common filtering card with accordion
  /*
    <common-common-filter-card
      class="my-3"
      :has-more-filters="true"
    >
      <template v-slot:header>
        <div style="height: 80px">Filters</div>
      </template>
      <template v-slot:content>
      </template>
    </common-common-filter-card>
    */
  export default {
    name: 'BaseCommonFilterCard',

    components: {
    },

    props: {
      value: {
        type: Boolean,
        default: false,
      },
      hasMoreFilters: {
        type: Boolean,
        default: false,
      },
    },

    computed: {
    },

    watch: {
      internalValue (val) {
        this.$emit('input', val)
      },
      value: {
        handler (val) {
          this.internalValue = val
        },
        immediate: true,
      },
    },

    methods: {
    },

    data () {
      return {
        internalValue: false,
      }
    },
  }
</script>
<style lang="scss" scoped>
  .v-expansion-panels .v-expansion-panel--disabled {
    color: rgba(0, 0, 0, 0.87);
  }
  .v-expansion-panels {
    background: #e2e2e2;
    .v-expansion-panel {
      background: #e2e2e2;
    }
    .v-expansion-panel-content {
      padding-top: 8px;
      border-top: 1px solid #9c9c9c;
    }
  }
  button, button:hover {
    background: transparent;
  }
</style>
