<template>
  <div>

    <UserLogin :hide="toggleUserLogin" v-if="userLogin"/>
    <Signup :hide="toggleSignup" v-if="signup" />
    <div class="top-bar" v-if="!user._id">
      <div :class="'nav-bar nav-bar-' + user.theme">
        <img :src="institution.logo" alt="" class="header-logo">
      </div>
      <ul class="guest-nav">
        <li @click="toggleUserLogin">User Login</li>
        <li @click="signup = !signup">Sign Up</li>
      </ul>
    </div>




    <v-app-bar
      app
      :color="user.theme"
      clipped-left
      :class="{
        'elevation-0': true,
      }"
      height="70"
      v-if="user._id"
    >
      <!-- <v-app-bar-nav-icon @click.stop="toggleDrawer"></v-app-bar-nav-icon> -->
      <v-img @click="$router.push('/templates')" :src="institution.logoWhite" responsive contain :max-width="60" />
      <span class="title ml-3 mr-5"></span>


      <v-btn 
        text
        @click="$router.push('/projects')"
        :class="{ 'nav-item': true, active: currentPath.indexOf('projects') !=-1 }" 
        :style="{ 'color': '#fff' }"
      >
        Projects
      </v-btn>
      <v-btn
        text
        @click="$router.push('/shared-files')"
        :class="{ 'nav-item': true, active: currentPath.indexOf('shared-files') !=-1 }"
        :style="{ 'color': '#fff' }"
      >
        Shared
      </v-btn>
      <v-btn
        text
        @click="$router.push('/order')"
        :class="{ 'nav-item': true, active: currentPath.indexOf('order') !=-1 }"
        :style="{ 'color': '#fff' }"
      >
        Order
      </v-btn>
      <v-btn
        v-if="isAdmin"
        text
        @click="$router.push('/users')"
        :class="{ 'nav-item': true, active: currentPath.indexOf('users') !=-1 }"
        :style="{ 'color': '#fff' }"
      >
        Users
      </v-btn>
      <v-btn
        text
        @click="$router.push('/requests')"
        :class="{ 'nav-item': true, active: currentPath.indexOf('requests') !=-1 }"
        :style="{ 'color': '#fff' }"
      >
        Requests
      </v-btn>

      <v-spacer></v-spacer>


      <v-menu
        :close-on-content-click="true"
        offset-y
      >
        <template v-slot:activator="{ on }">
          <div v-on="on" class="profile">
            <v-avatar>
              <img
                :src="user.image ?  ('/api/static/' + user._id + '/' +user._id + '.png') : emptyProfile"
                alt="John"
              >
            </v-avatar>
            <span class="ml-2">{{ user.username }}</span>
          </div>
        </template>

        <v-card>
          <v-list>
            <v-list-item
              @click="$router.push('/profile')"
            >
              <!-- <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon> -->
              <v-list-item-title>Profile</v-list-item-title>
            </v-list-item>
            <v-list-item
              @click="logout"
            >
              <v-list-item-title>Log out</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>

    </v-app-bar>
  </div>
</template>
<script>
import auth from "../auth";
import { mapMutations } from "vuex";
import { mapGetters, mapActions } from 'vuex';
import emptyProfile from "../img/empty_profile.png"
import UserLogin from "./login/login_form";
import Signup from "./login/signup";
import { getInst } from '@/institutions';
export default {
  name: "header_main",
  data() {
    return {
      emptyProfile: emptyProfile,
      userDropdown: false,
      currentPath: window.location.href,

      userLogin: false,
      signup: false
    };
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),

    institution() {
      return getInst();
    },
    isAdmin () {
      return this.user && this.user.role === 'admin'
    },
  },
  watch:{
    $route (to, from){
      this.currentPath = to.path;
    }
  }, 
  methods: {
    ...mapMutations("projects", ["resetState"]),
    toggleUserDropdown() {
      this.userDropdown = !this.userDropdown;
    },
    logout() {
      this.resetState();
      auth.logout();
    },
    isActive(path) {
      return (location.href.indexOf(path) != -1);
    },

    toggleUserLogin() {
      this.userLogin = !this.userLogin;
    },
    toggleSignup() {
      this.signup = !this.signup;
    },
  },
  components: {
    UserLogin,
    Signup
  }
};
</script>
<style lang="scss">
.top-bar {
  height: 70px;
  width: 100%;
  text-align: center;
  position: relative;
  background: #66d0f7;
  z-index: 10;
}
.nav-bar {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;

}
.nav-item {
  color: #fff;
  text-shadow: 1px 1px rgba(255, 255, 255, 0.1);
  font-size: 13pt;
  margin: 0px 20px;
  text-align: center;
  font-weight: 300;
  cursor: pointer;
  position: relative;
  &:hover {
    color: #31a6d1;
  }
  &.active {
    color: #fff;
    border-bottom: solid 2px #fff;
  }
}
.nav-bar img {
  height: 70%;
  transition: all 0.3s ease;
  cursor: pointer;
}
.nav-bar img:hover {
  transform: scale(1.05, 1.05);
}
.user-nav {
  position: absolute;
  height: 100%;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
}
.simple-dropdown {
  position: absolute;
  background: #ffffff;
  border-radius: 3px;
  box-shadow: 1px 3px 3px rgba(0, 0, 0, 0.2);
  right: 0;
  top: 45px;
  min-width: 170px;
}
.simple-dropdown li {
  padding: 10px;
  text-align: left;
  white-space: nowrap;
  color: #4a4a4a;
}
.profile-avatar {
  width: 45px;
  height: 45px;
  border-radius: 50%;
}

.guest-nav {
  height: 100%;
  top: 0;
  position: absolute;
  right: 0;
  padding: 15px;
  display: flex;
  align-items: center;
}
.guest-nav li {
  padding: 10px;
  color: #fff;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  border: 1px solid #fff;
  border-radius: 5px;
  margin-right: 5px;
}
.guest-nav li:hover {
  color: #b9ebff;
}
.profile {
  cursor: pointer;
  color: #FFF;
}

</style>
