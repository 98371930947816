<template>
  <div class="copyright">
    <div class="no-sidebar-container">
      <div class="main-title">Search Intellectual Property for:</div>
      <div style="display: flex; margin: 0px 20px">
        <v-checkbox label="Copyrights" v-model="include.copyright" hide-details single-line></v-checkbox>
        <v-checkbox label="Patents" v-model="include.patent" hide-details single-line></v-checkbox>
        <v-checkbox label="Trademarks" v-model="include.trademarks" hide-details single-line></v-checkbox>
      </div>
    </div>
    <div class="ofac-content">
      
      <StandardInput
        field="Email To"
        v-model="emailTos"
        width="100%"
        placeholder="jason.erb@unitedcorporate.com,jason@commonbrain.io"
      />    

      <div class="criteria-cont">
        <div style="display: flex; justify-content: space-between; align-items: center; padding-bottom: 10px">
          <p>
            Names
          </p>
          <button @click="addCriterion()" class="modal-btn" type="submit" :style="{background: user.theme}">
            Add name
          </button>
        </div>

        <div v-for="(search, index) in searches" class="criterion-cont" flex-wrap>
          <v-flex xs12>
            <StandardInput
              field="Name"
              v-model="search.name"
              placeholder="Marvel Entertainment"
              width="100%"
            />
          </v-flex>
          <!-- <v-flex xs6>
            
            <v-slider
              v-model="search.minimumScore"
              label="Minimum Score"
              :max="100"
              :min="50"
              :step="1"
              :color="user.theme"
              thumb-label
            ></v-slider>
          </v-flex> -->
          <div style="display: flex; justify-content: flex-end;">
            <button @click="removeCriterion(index)" class="modal-btn" :style="{background: user.theme, width: '110px', height: '30px', 'font-size': '11px', padding: '5px'}">
              Remove Name
            </button>
          </div>
        </div>
      </div>
      <div style="text-align: center;">
        <button @click="startSearch()" class="modal-btn" :style="{background: user.theme}">
          <img v-if="isLoading" style="width:25px" src="../../img/spinner_white.svg"/>
          Request Search
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import StandardInput from "../form_elements/standard_input";
import ApiWrapper from '@/shared/utils/ApiWrapper';

import { mapGetters, mapActions } from 'vuex';
export default {
  name: "copyright",
  data() {
    return {
      emailTos: '',
      searchKeys: '',
      searches: [{
        type: 'All',
        address: '',
        name: '',
        city: '',
        ID: '',
        state: '',
        program: '',
        country: '',
        minimumScore: 100,
        list: ''
      }],
      isLoading: false,
      // results: JSON.parse('[{"search":{"name":"japan","minimumScore":100},"result":[{"address":"8th Floor, Shiba East Building, 2-3-9 Shiba, Minato-ku","link":"https://sanctionssearch.ofac.treas.gov/Details.aspx?id=6674","list":"Non-SDN","name":"ASCOTEC JAPAN K.K.","program":"IRAN","score":"100","type":"Entity"}]}]')
      results: [],

      include: {
        copyright: true,
        patent: true,
        trademarks: true,
      }
    };
  },
  components: {
    StandardInput
  },
  mouted() {},
  methods: {
    addCriterion() {
      this.searches.push({
        type: 'All',
        address: '',
        name: '',
        city: '',
        ID: '',
        state: '',
        program: '',
        country: '',
        minimumScore: 100,
        list: ''
      })
    },
    removeCriterion(index) {
      this.searches.splice(index, 1);
    },
    extractObj(obj) {
      let str = 'Criteria: ';
      for(let key in obj) {
        str += `${key}=${obj[key]} `;
      }
      return str;
    },
    startSearch() {
      if (!this.emailTos || !this.searches.length)
        return;
      this.results = [];
      let searches = this.searches;
      // this.isLoading = true;
      ApiWrapper
        .post(
          "/api/copyright/scrap",  
          { searches: searches, emails: this.emailTos.split(','), include: this.include }
        )
        .then(res => {
          console.log(res.data)
          alert(res.data.message);
        });
    }
  },
  computed: {
    ...mapGetters({
      user: 'user',
    }),}
};
</script>
<style>
.ofac-content {
  margin: 20px;
}
.criteria-cont {
  margin: 30px 0px;

}
.criterion-cont {
  border-top: 1px solid #eaeaea;
}
.copyright input::placeholder {
  color: #3f3f3f;
}
</style>
